import { useEffect } from "react";
import { PlaidLinkOnSuccess, usePlaidLink } from "react-plaid-link";
import {
  BusinessConfigKey,
  getBusinessConfigurationItem,
  selectBusinessConfigurationItem,
} from "./businessConfigSlice";
import {
  createPlaidIDVToken,
  getAvailableBalance,
  getBusinessAccount,
  makeSelectBusinessAccount,
  selectFlumeBalanceState,
  selectPlaidIDVToken,
} from "./businessSlice";
import { selectPrimaryBusinessAccount } from "../user/userSlice";
import { BusinessAccount } from "../../generated/openapi";
import { useEffectOnce } from "../../shared/hook";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

interface AccountSelectOptions {
  additionalDependencies?: any[];
  refetch: boolean;
}

export const useBusinessAccount = (id: string, options: Partial<AccountSelectOptions> = {}) => {
  const allOptions: AccountSelectOptions = { refetch: true, ...options };
  const dispatch = useAppDispatch();
  const account = useAppSelector<BusinessAccount>(makeSelectBusinessAccount(id));

  useEffectOnce(
    () => id && (!account?.id || allOptions.refetch),
    () => dispatch(getBusinessAccount(id)),
    [dispatch, id, account?.id, allOptions.refetch]
  );

  return account;
};

export const usePrimaryBusinessAccount = (options: Partial<AccountSelectOptions> = {}) => {
  const userAccount = useAppSelector(selectPrimaryBusinessAccount);
  return useBusinessAccount(userAccount?.business_account_id, options);
};

/**
 * Gets a business configuration item from the store. Fetches it from the server if undefined
 * or option set to refetch.
 * @param businessAccountId ID of the business account the config item belongs to.
 * @param key the configuration item's key.
 * @param options additional options, including whether to refetch from server.
 * @returns business config item from store.
 */
export const useBusinessConfigItem = (
  businessAccountId: string,
  key: BusinessConfigKey,
  options: Partial<AccountSelectOptions> = {}
) => {
  const dispatch = useAppDispatch();
  const config = useAppSelector(selectBusinessConfigurationItem(key));

  useEffectOnce(
    () => businessAccountId && (config === undefined || options.refetch),
    () => dispatch(getBusinessConfigurationItem({ businessAccountId, key })),
    [businessAccountId, config, dispatch, options.refetch]
  );

  return config;
};

/**
 * Gets the redux state of the balance of a Flume account. Fetches it from the server if undefined or option set to refetch.
 * @param businessAccountId ID of the business account that owns the Flume account.
 * @param options additional options, including whether to refetch from the server.
 */
export const useFlumeBalance = (
  businessAccountId: string,
  options: Partial<AccountSelectOptions> = {}
) => {
  const dispatch = useAppDispatch();
  const balance = useAppSelector(selectFlumeBalanceState);

  useEffectOnce(
    () => businessAccountId && (!balance?.fulfilled || options.refetch),
    () => dispatch(getAvailableBalance(businessAccountId)),
    [
      balance?.fulfilled,
      businessAccountId,
      dispatch,
      options.refetch,
      ...(options.additionalDependencies || []),
    ]
  );

  return balance;
};

/**
 * Set up Plaid IDV.
 * @param businessAccountId ID of the business account
 * @param onSuccess callback for after Plaid IDV is completed.
 * @param condition condition to wait for before creating token.
 * @returns the plaid link options, including method for opening plaid IDV widget.
 */
export const usePlaidIDV = (
  businessAccountId: string,
  onSuccess: PlaidLinkOnSuccess,
  condition: boolean = true
) => {
  const dispatch = useAppDispatch();
  const tokenState = useAppSelector(selectPlaidIDVToken);

  useEffect(() => {
    condition && businessAccountId && dispatch(createPlaidIDVToken({ businessAccountId }));
  }, [condition, businessAccountId, dispatch]);

  return usePlaidLink({ token: tokenState.data?.link_token, onSuccess });
};
