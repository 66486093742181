import { rewriteFramesIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { Route } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import env, { isDeployed } from "./config";
import { cleanObject } from "./shared/clean";
import {
  CreateRoutesFromChildren,
  MatchRoutes,
  UseEffect,
  UseLocation,
  UseNavigationType,
} from "@sentry/react/types/types";

export let sentryLastEventId;

type RouterInstrumentationFunctions = {
  useEffect: UseEffect;
  useLocation: UseLocation;
  useNavigationType: UseNavigationType;
  createRoutesFromChildren: CreateRoutesFromChildren;
  matchRoutes: MatchRoutes;
};
export const initialize = (funcs: RouterInstrumentationFunctions) =>
  isDeployed() &&
  Sentry.init({
    dsn: "https://e9cac4e243c545868ca23f78a88e8877@o1061269.ingest.sentry.io/6051574",
    environment: env.env,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          funcs.useEffect,
          funcs.useLocation,
          funcs.useNavigationType,
          funcs.createRoutesFromChildren,
          funcs.matchRoutes
        ),
        tracingOrigins: [
          "localhost:3000",
          /(^flume)|((\/|(www\.))flume-dev\.com)/,
          /(^flume)|((\/|(www\.))flumepay\.com)/,
        ],
      }),
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
      rewriteFramesIntegration({
        root: __dirname || process.cwd(),
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    beforeSend: (event) => {
      sentryLastEventId = event.event_id;
      return event;
    },
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    attachStacktrace: true,
    tracesSampleRate: 0.2,
    release: env.release,
  });

export const setTransactionId = (): string => {
  const transaction_id = uuidv4();
  isDeployed() && Sentry.configureScope((scope) => scope.setTag("transaction_id", transaction_id));
  return transaction_id;
};

export const resetTransactionId = () => {
  isDeployed() && Sentry.configureScope((scope) => scope.setTag("transaction_id", ""));
};

export const logSentryError = (error: string | Error) => {
  if (isDeployed()) {
    Sentry.captureException(error);
  }
};

export const setSentryUser = (email: string) => {
  isDeployed() && Sentry.setUser({ email: email });
};

const redactedProperties = ["ssn", "tax_id"];
export const logRejectedThunk = (state, action) => {
  if (!isDeployed()) return;
  let detailedMsg: string;
  try {
    detailedMsg = JSON.parse(action.payload?.error || null)?.error;
  } catch (e) {
    detailedMsg = action.payload?.error;
  }
  Sentry.withScope((scope) => {
    scope.setTransactionName(action.type);
    scope.setContext(
      "payload",
      cleanObject(action.meta.arg?.data, redactedProperties) as Record<string, unknown>
    );
    logSentryError(detailedMsg || action.error.message);
  });
  resetTransactionId();
};

export const SentryRoute = Sentry.withSentryReactRouterV6Routing(Route);
