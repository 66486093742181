import { FleetCard } from "../../generated/openapi";

export const fleetCardToUI = (card: FleetCard) => ({
  ...card,
  profile: { ...card.profile, spend_per_day: card.profile?.spend_per_day / 100 },
});

export const fleetCardToAPI = (card: FleetCard) => card;

export const maskCreditCardNumber = (ccn: string, maskType: string = "*") => {
  return ccn
    ? `${maskType.repeat(3)}${ccn.substring(Math.max(ccn.length - 5, 0), ccn.length)}`
    : "";
};

/**
 * Cleans a business name for embossing. Limits to 20 characters and removes special characters.
 * @param name Business name to clean
 * @returns the cleaned business name.
 */
export const cleanCompanyName = (name: string) => {
  return name.replace(/[^0-9a-zA-Z\s]/g, "").slice(0, 20);
};
