import { createAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { ErrorResponse, ValidationError, ValidationErrorResponse } from "../../generated/openapi";
import { RootState } from "../../store";
import { UniversalError } from "../../shared/error/UniversalError";

let id = 0;
export const idGenerator = () => {
  return ++id;
};

export enum MessageType {
  ERROR = "danger",
  INFO = "info",
  WARNING = "warning",
  SUCCESS = "success",
  INVALID = "invalid",
}

/**
 * Creates a success/error notification based off of a redux dispatch response.
 * @param dispatcher The redux dispatcher
 * @param successMsg the message to display on the success notification.
 * @param errorMsg the message to display on the error notification.
 */
export const notifyDispatchResponse =
  (dispatcher: any, successMsg: string, errorMsg: string) => (response: any) => {
    if (!response["error"]) {
      dispatcher(
        createNotification({ type: MessageType.SUCCESS, message: successMsg, timeout: 3000 })
      );
    } else {
      dispatcher(createNotification({ type: MessageType.ERROR, message: errorMsg, timeout: 3000 }));
    }
  };

export interface Notification {
  message: string;
  id: number;
  code?: string;
  truncate?: boolean;
  type: MessageType;
  timeout?: number;
}

export interface ValidationErrorNotification extends ValidationError {
  id: number;
  error: ValidationError;
  type: MessageType;
}

export const notifyError = createAsyncThunk<Notification, ErrorResponse, {}>(
  "notify/error",
  async (arg: ErrorResponse, thunk) => {
    try {
      const err = JSON.parse(arg?.error);
      return {
        id: idGenerator(),
        timeout: null,
        code: "GEN001",
        message: err.error,
        type: MessageType.ERROR,
        truncate: false,
      };
    } catch (e) {
      return {
        id: idGenerator(),
        timeout: null,
        code: "GEN001",
        message: arg?.error,
        type: MessageType.ERROR,
        truncate: false,
      };
    }
  }
);
export const createNotification = createAsyncThunk<Notification, Partial<Notification>, {}>(
  "notify/create",
  async (arg: Partial<Notification>, thunk) => {
    return { message: "", type: MessageType.INFO, ...arg, id: idGenerator() };
  }
);
export const notifyValidationError = createAsyncThunk<
  ValidationErrorNotification[],
  ValidationErrorResponse,
  {}
>("notify/validation", async (response: ValidationErrorResponse) => {
  return response.validation_errors.map((err) => ({
    type: MessageType.INVALID,
    error: err,
    message: err.reason,
    timeout: null,
    id: idGenerator(),
    ...err,
  }));
});
export const notifyValidationClear = createAction("notify/validation/clear");

export const notifyValidationRemove = createAsyncThunk<
  ValidationErrorNotification,
  ValidationErrorNotification,
  {}
>("notify/validation/remove", async (arg: ValidationErrorNotification, thunk) => arg);

export const notifyRemove = createAsyncThunk<Notification, Notification, {}>(
  "notify/remove",
  async (arg: Notification, thunk) => arg
);

export const selectNotifications = (state) => state.notification.messages;
export const selectValidationNotifications = (state: RootState) => state.notification.validation;
export const selectValidationNotification = (field: string) =>
  createSelector(selectValidationNotifications, (notifications) =>
    notifications.find((notification) => notification.field === field)
  );
export const selectValidationNotificationByCode = (code: UniversalError) =>
  createSelector(selectValidationNotifications, (notifications) =>
    notifications.find((notice) => notice.code === code)
  );
export const selectValidationNotificationsByField = (field: string) =>
  createSelector(selectValidationNotifications, (notifications) =>
    notifications.filter((notice) => notice.field === field)
  );
export const selectValidationNotificationsByFields = (fields: string[]) =>
  createSelector(selectValidationNotifications, (notifications) =>
    notifications.filter((notice) => fields.includes(notice.field))
  );

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    messages: [],
    validation: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(notifyError.fulfilled, (state, action) => {
      state.messages.push(action.payload);
    });

    builder.addCase(notifyRemove.fulfilled, (state, action) => {
      const arg = action.payload;
      state.messages = state.messages.filter((msg) => msg.id !== arg.id);
    });

    builder.addCase(notifyValidationError.fulfilled, (state, action) => {
      state.validation = [...state.validation, ...action.payload];
    });

    builder.addCase(createNotification.fulfilled, (state, action) => {
      state.messages.push(action.payload);
    });

    builder.addCase(notifyValidationClear, (state, action) => {
      state.validation = [];
    });

    builder.addCase(notifyValidationRemove.fulfilled, (state, action) => {
      state.validation = state.validation.filter((val) => val.id !== action.payload.id);
    });
  },
});

export default notificationSlice.reducer;
