import { useOktaAuth } from "@okta/okta-react";
import { Navigate, useSearchParams } from "react-router-dom";
import OktaSignInWidget, { RegistrationForm } from "./okta";
import config from "../../config";
import { getPath, routes } from "../../http/route";
import { Storage } from "../../shared/storage";

// Query param keys
const EMAIL = "email";
const FIRST_NAME = "first_name";
const LAST_NAME = "last_name";

interface LoginProps {
  register?: boolean;
}
export const Login = ({ register = false }: LoginProps) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [queryParams] = useSearchParams();

  function handleSuccess(tokens) {
    Storage.setLastLogin(new Date().toISOString());
    oktaAuth.handleLoginRedirect(tokens);
  }

  function handleError(error) {
    console.log("Error occurred during login %o", error);
  }

  if (!authState) return null;

  return authState.isAuthenticated ? (
    <Navigate to={getPath(routes.app)} replace />
  ) : (
    <OktaSignInWidget
      config={config.auth}
      initial={getRegistrationFormDefaults(queryParams)}
      onSuccess={handleSuccess}
      onError={handleError}
      register={register}
    />
  );
};

/**
 * Builds out the default form values object from the query params.
 * @param params the URL search params.
 * @returns the default form values.
 */
const getRegistrationFormDefaults = (params: URLSearchParams): RegistrationForm => {
  return {
    email: params.has(EMAIL) ? decodeURI(params.get(EMAIL)) : "",
    firstName: params.has(FIRST_NAME) ? decodeURI(params.get(FIRST_NAME)) : "",
    lastName: params.has(LAST_NAME) ? decodeURI(params.get(LAST_NAME)) : "",
  };
};
