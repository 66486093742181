import { Chip } from "@mui/material";
import { forwardRef } from "react";
import "./center-layout.scss";
import { useBodyClass } from "../../component/hook";
import env from "../../config";
import { useI18n } from "../../i18n";

interface CenterLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  dark?: boolean;
  showFooter?: boolean;
  showHeader?: boolean;
}
export const CenterLayout = forwardRef<HTMLDivElement, CenterLayoutProps>(
  (
    { children, className, dark = false, showFooter = false, showHeader = false, ...props },
    ref
  ) => {
    useBodyClass(dark ? ["center-layout", "center-layout-dark"] : "center-layout");
    const t = useI18n({ namespaces: ["layout"], prefix: "centerLayout" });

    return (
      <>
        {showHeader && (
          <header className="center-layout-header" data-testid="layout-header">
            <img
              alt={t("logo")}
              className="center-layout-header-logo"
              src={dark ? "/img/wex-select-logo-white.svg" : "/img/wex-select-primary-logo.svg"}
            />
            <Chip className="ml-3" color="error" label={t("pill")} variant="rounded" />
          </header>
        )}
        <div className={`center-layout-container ${className || ""}`} ref={ref} {...props}>
          {children}
        </div>
        {showFooter && (
          <footer
            className="d-flex align-items-center justify-content-between flex-column flex-md-row center-layout-footer"
            data-testid="layout-footer"
          >
            <div className="center-layout-footer-copyright">
              <p className="font-weight-semibold font-size-caption-1 mb-3">
                {t("copyright.title", { replace: { year: new Date().getFullYear() } })}
              </p>
            </div>
            <div className="center-layout-footer-contact">
              <p className="font-weight-bold mb-0">{t("contact.title")}</p>
              <p className="mb-0">{env.support.email}</p>
              <p className="mb-0">{env.support.phoneNumber}</p>
            </div>
          </footer>
        )}
      </>
    );
  }
);
