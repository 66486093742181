import { Paper } from "@mui/material";
import Button, { ButtonVariant } from "../button";
import { useState } from "react";
import { showReportDialog } from "@sentry/react";
import "./error.scss";
import { ErrorDetail } from "./ErrorDetail";
import { useI18n } from "../../i18n";

/**
 * Gets translations to use for the Sentry feedback report dialog.
 * @returns object that includes all the translations to be spread into the config options for the Sentry report dialog.
 */
const useReportDialogTranslations = () => {
  const t = useI18n({ namespaces: ["errors"], prefix: "fallback.feedback.dialog" });
  return {
    title: t("title"),
    subtitle: t("description1"),
    subtitle2: t("description2"),
    labelName: t("form.field.name"),
    labelEmail: t("form.field.email"),
    labelComments: t("form.field.description"),
    labelClose: t("form.close"),
    labelSubmit: t("form.submit"),
    errorGeneric: t("form.error.generic"),
    errorFormEntry: t("form.error.validation"),
    successMessage: t("form.confirmation"),
  };
};

export type FallbackProps = {
  error: Error;
  componentStack: string;
  eventId: string;
  resetError: () => void;
  email?: string;
  name?: string;
  showDetails?: boolean;
  showTryAgain?: boolean;
};

export const Fallback = ({
  email,
  name,
  showDetails = false,
  showTryAgain = false,
  error,
  componentStack,
  resetError,
  eventId,
}: FallbackProps) => {
  const t = useI18n({ namespaces: ["errors"], prefix: "fallback" });
  const dialogTranslations = useReportDialogTranslations();

  const [detailOpen, setErrorDetailOpen] = useState(false);

  return (
    <Paper className="container flex-grow-1 my-2 d-flex justify-content-center align-items-stretch flex-column error-fallback">
      <div className="d-flex justify-content-center align-items-center flex-column p-2 align-self-center error-fallback-text">
        <img src="/img/search-empty.svg" alt="" />
        <h2>{t("title")}</h2>
        <p className="text-muted font-size-body-condensed text-center">{t("description")}</p>
      </div>

      <div className="d-flex justify-content-center">
        <Button
          className="mr-2"
          variant={ButtonVariant.SECONDARY}
          onClick={() =>
            showReportDialog({
              eventId: eventId,
              user: {
                email: email,
                name: name,
              },
              ...dialogTranslations,
            })
          }
        >
          {t("feedback.cta")}
        </Button>
        {showDetails ? (
          detailOpen ? (
            <Button onClick={() => setErrorDetailOpen(false)} variant={ButtonVariant.SECONDARY}>
              {t("hideDetails")}
            </Button>
          ) : (
            <Button onClick={() => setErrorDetailOpen(true)} variant={ButtonVariant.SECONDARY}>
              {t("showDetails")}
            </Button>
          )
        ) : (
          <></>
        )}

        <Button onClick={() => window.location.reload()} className="mx-2">
          {t("refresh")}
        </Button>
        {showTryAgain ? <Button onClick={resetError}>{t("tryAgain")}</Button> : <></>}
      </div>
      {detailOpen && <ErrorDetail componentStack={componentStack} error={error} />}
    </Paper>
  );
};
