import { useOktaAuth } from "@okta/okta-react";
import { Link, Paper } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./accept-terms.scss";
import { acceptTerms, loadCurrentUser, selectCurrentUser } from "./userSlice";
import { Button } from "../../component/button";
import { getPath, routes } from "../../http/route";
import { Translation, useI18n } from "../../i18n";
import { TranslationFunction } from "../../i18n/hook";
import { DateFormat, formatDate } from "../../shared/format";
import { hasScrolledToBottom } from "flume/shared/view";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

interface TermsTranslationProps {
  i18nKey: string;
  replace?: Record<string, string | number>;
  href?: string;
  t: TranslationFunction;
}

const TermsTranslation = ({ i18nKey, replace, href, t }: TermsTranslationProps) => {
  return (
    <Translation
      components={[<Link href={href} />]}
      i18nKey={i18nKey}
      replace={replace}
      translationFunc={t}
    />
  );
};
const TermsParagraph = (props: TermsTranslationProps) => {
  return (
    <p>
      <TermsTranslation {...props} />
    </p>
  );
};

export const AcceptTerms = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(selectCurrentUser);
  const effectiveDate = formatDate(new Date(), DateFormat.DATE);
  const { oktaAuth } = useOktaAuth();
  const t = useI18n({ namespaces: ["userModule"], prefix: "acceptTerms" });

  const termsContainer = useRef<HTMLDivElement>();
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  const scrollToBottom = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    termsContainer.current.scrollTo({
      top: termsContainer.current.scrollHeight - termsContainer.current.offsetHeight,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const container = termsContainer.current;
    container &&
      container.addEventListener("scroll", () => {
        if (hasScrolledToBottom(container)) {
          setScrolledToBottom(true);
        }
      });
  }, []);

  useEffect(() => {
    if (oktaAuth.isAuthenticated) {
      dispatch(loadCurrentUser());
    }
  }, [dispatch, oktaAuth.isAuthenticated]);

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      dispatch(acceptTerms()).then(() => navigate(getPath(routes.app.dashboard)));
    },
    [dispatch, navigate]
  );

  const showCta = user && !user.accepted_terms;

  return (
    <Paper className="terms mx-auto">
      <form className="terms-container" onSubmit={handleSubmit}>
        <div className="terms-header">
          <img alt={t("alt")} className="terms-logo" src="/img/wex-select-primary-logo.svg" />
        </div>
        <hr className="m-0" />
        <div className="terms-content" data-testid="terms-content" ref={termsContainer}>
          <TermsTranslation i18nKey="termsSummary.title" t={t} />
          <TermsParagraph
            i18nKey="termsSummary.effectiveDate"
            replace={{ effectiveDate: effectiveDate }}
            t={t}
          />
          <TermsParagraph i18nKey="termsSummary.paragraph1" t={t} />
          <TermsParagraph i18nKey="termsSummary.paragraph2" t={t} />
          <TermsParagraph i18nKey="termsSummary.paragraph3" t={t} />
          <TermsParagraph i18nKey="termsSummary.paragraph4.text" t={t} />
          <ul className="c3 lst-kix_list_1-0 start">
            <li className="c2 c20 li-bullet-0">
              <TermsTranslation
                i18nKey="termsSummary.paragraph4.item1"
                href="https://www.wexinc.com/privacy-policy/"
                t={t}
              />
            </li>
            <li className="c2 c20 li-bullet-0">
              <TermsTranslation
                i18nKey="termsSummary.paragraph4.item2"
                href="https://www.wexinc.com/wex-cookie-notice/"
                t={t}
              />
            </li>
            <li className="c2 c20 li-bullet-0">
              <TermsTranslation
                i18nKey="termsSummary.paragraph4.item3"
                href="https://www.wexinc.com/terms-of-use/"
                t={t}
              />
            </li>
            <li className="c2 c20 li-bullet-0">
              <TermsTranslation
                i18nKey="termsSummary.paragraph4.item4"
                href="https://flumepay.com/fees"
                t={t}
              />
            </li>
            <li className="c2 c20 li-bullet-0">
              <TermsTranslation i18nKey="termsSummary.paragraph4.item5" t={t} />
            </li>
            <li className="c2 c20 li-bullet-0">
              <TermsTranslation i18nKey="termsSummary.paragraph4.item6" t={t} />
            </li>
          </ul>
          <br />
          <TermsParagraph i18nKey="termsSummary.paragraph5" t={t} />
          <TermsParagraph
            i18nKey="termsSummary.paragraph6"
            href="https://flumepay.com/legal/terms-of-service"
            t={t}
          />
          <TermsParagraph i18nKey="termsSummary.paragraph7" t={t} />
          <ol className="c3 lst-kix_list_2-0 start" start={1}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="eligibilityRequirements.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="eligibilityRequirements.paragraph1" t={t} />
          <TermsParagraph i18nKey="eligibilityRequirements.paragraph2" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={2}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="messagingConsent.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="messagingConsent.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={3}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="electronicCommunications.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="electronicCommunications.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-1 start" start={1}>
            <li className="c11 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="electronicCommunications.scopeOfConsent.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="electronicCommunications.scopeOfConsent.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={2}>
            <li className="c11 li-bullet-0">
              <h2>
                <TermsTranslation
                  i18nKey="electronicCommunications.rightToReceivePaperCopies.title"
                  t={t}
                />
              </h2>
            </li>
          </ol>
          <TermsParagraph
            i18nKey="electronicCommunications.rightToReceivePaperCopies.paragraph1"
            t={t}
          />
          <ol className="c3 lst-kix_list_2-1" start={3}>
            <li className="c11 li-bullet-0">
              <h2>
                <TermsTranslation
                  i18nKey="electronicCommunications.rightToWithdrawConsent.title"
                  t={t}
                />
              </h2>
            </li>
          </ol>
          <TermsParagraph
            i18nKey="electronicCommunications.rightToWithdrawConsent.paragraph1"
            t={t}
          />
          <ol className="c3 lst-kix_list_2-1" start={4}>
            <li className="c11 li-bullet-0">
              <h2>
                <TermsTranslation
                  i18nKey="electronicCommunications.hardwareAndSoftwareRequirements.title"
                  t={t}
                />
              </h2>
            </li>
          </ol>
          <TermsParagraph
            i18nKey="electronicCommunications.hardwareAndSoftwareRequirements.paragraph1"
            t={t}
          />
          <ol className="c3 lst-kix_list_2-1" start={5}>
            <li className="c11 li-bullet-0">
              <h2>
                <TermsTranslation
                  i18nKey="electronicCommunications.updatingYourRecords.title"
                  t={t}
                />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="electronicCommunications.updatingYourRecords.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={4}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="creatingBusinessAccount.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="creatingBusinessAccount.paragraph1" t={t} />
          <TermsParagraph i18nKey="creatingBusinessAccount.paragraph2" t={t} />
          <TermsParagraph i18nKey="creatingBusinessAccount.paragraph3" t={t} />
          <TermsParagraph i18nKey="creatingBusinessAccount.paragraph4" t={t} />
          <TermsParagraph i18nKey="creatingBusinessAccount.paragraph5" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={5}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="accountSecurity.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="accountSecurity.paragraph1" t={t} />
          <TermsParagraph i18nKey="accountSecurity.paragraph2" t={t} />
          <TermsParagraph i18nKey="accountSecurity.paragraph3" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={6}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="linkedAccounts.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="linkedAccounts.paragraph1" t={t} />
          <TermsParagraph i18nKey="linkedAccounts.paragraph2" t={t} />
          <TermsParagraph i18nKey="linkedAccounts.paragraph3" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={7}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="flumeAccount.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="flumeAccount.paragraph1" t={t} />
          <TermsParagraph i18nKey="flumeAccount.paragraph2" t={t} />
          <TermsParagraph i18nKey="flumeAccount.paragraph3" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={6}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="depositingFundsToYourFlumeAccount.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="depositingFundsToYourFlumeAccount.paragraph1" t={t} />
          <TermsParagraph i18nKey="depositingFundsToYourFlumeAccount.paragraph2" t={t} />
          <TermsParagraph i18nKey="depositingFundsToYourFlumeAccount.paragraph3" t={t} />
          <TermsParagraph i18nKey="depositingFundsToYourFlumeAccount.paragraph4" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={7}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="fundsAvailability.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="fundsAvailability.paragraph1" t={t} />
          <TermsParagraph i18nKey="fundsAvailability.paragraph2" t={t} />
          <TermsParagraph i18nKey="fundsAvailability.paragraph3" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={8}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="withdrawingFundsFromYourFlumeAccount.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="withdrawingFundsFromYourFlumeAccount.paragraph1" t={t} />
          <TermsParagraph i18nKey="withdrawingFundsFromYourFlumeAccount.paragraph2" t={t} />
          <TermsParagraph i18nKey="withdrawingFundsFromYourFlumeAccount.paragraph3" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={9}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="availableBalance.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="availableBalance.paragraph1" t={t} />
          <TermsParagraph i18nKey="availableBalance.paragraph2" t={t} />
          <TermsParagraph i18nKey="availableBalance.paragraph3" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={10}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="statements.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="statements.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={11}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="negativeBalance.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="negativeBalance.paragraph1" t={t} />
          <TermsParagraph i18nKey="negativeBalance.paragraph2" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={12}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="stopPayments.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="stopPayments.paragraph1" t={t} />
          <TermsParagraph i18nKey="stopPayments.paragraph2" t={t} />
          <TermsParagraph i18nKey="stopPayments.paragraph3" t={t} />
          <TermsParagraph i18nKey="stopPayments.paragraph4" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={13}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="yourLiabilityForUnauthorizedTransfers.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="yourLiabilityForUnauthorizedTransfers.paragraph1" t={t} />
          <TermsParagraph i18nKey="yourLiabilityForUnauthorizedTransfers.paragraph2" t={t} />
          <TermsParagraph i18nKey="yourLiabilityForUnauthorizedTransfers.paragraph3" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={14}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="ourRightOfSetoff.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="ourRightOfSetoff.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={8}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="sendFundsGetPaidFlumePayAndSmartFunding.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="sendFundsGetPaidFlumePayAndSmartFunding.paragraph1" t={t} />
          <TermsParagraph i18nKey="sendFundsGetPaidFlumePayAndSmartFunding.paragraph2" t={t} />
          <TermsParagraph i18nKey="sendFundsGetPaidFlumePayAndSmartFunding.paragraph3" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={15}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="vendorInformation.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="vendorInformation.paragraph1" t={t} />
          <TermsParagraph i18nKey="vendorInformation.paragraph2" t={t} />
          <TermsParagraph i18nKey="vendorInformation.paragraph3" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={16}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="sendFunds.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="sendFunds.paragraph1" t={t} />
          <TermsParagraph i18nKey="sendFunds.paragraph2" t={t} />
          <TermsParagraph i18nKey="sendFunds.paragraph3" t={t} />
          <TermsParagraph i18nKey="sendFunds.paragraph4" t={t} />
          <ol className="c3 lst-kix_list_2-2 start" start={1}>
            <li className="c2 c17 li-bullet-0">
              <h3>
                <TermsTranslation i18nKey="sendFunds.cancellationAndChanges.title" t={t} />
              </h3>
            </li>
          </ol>
          <TermsParagraph i18nKey="sendFunds.cancellationAndChanges.paragraph1" t={t} />
          <TermsParagraph i18nKey="sendFunds.cancellationAndChanges.paragraph2" t={t} />
          <ol className="c3 lst-kix_list_2-2" start={2}>
            <li className="c2 c17 li-bullet-0">
              <h3>
                <TermsTranslation i18nKey="sendFunds.returnedPayments.title" t={t} />
              </h3>
            </li>
          </ol>
          <TermsParagraph i18nKey="sendFunds.returnedPayments.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={17}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="getPaid.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="getPaid.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-2" start={3}>
            <li className="c2 c17 li-bullet-0">
              <h3>
                <TermsTranslation i18nKey="getPaid.sendingAnInvoice.title" t={t} />
              </h3>
            </li>
          </ol>
          <TermsParagraph i18nKey="getPaid.sendingAnInvoice.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-2" start={4}>
            <li className="c2 c16 li-bullet-0">
              <h3>
                <TermsTranslation i18nKey="getPaid.acceptingPaymentCardPayments.title" t={t} />
              </h3>
            </li>
          </ol>
          <TermsParagraph i18nKey="getPaid.acceptingPaymentCardPayments.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={18}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="flumePay.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="flumePay.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={19}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="smartFunding.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="smartFunding.paragraph1" t={t} />
          <TermsParagraph i18nKey="smartFunding.paragraph2" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={9}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="agreementToOriginateAchEntries.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="agreementToOriginateAchEntries.paragraph1" t={t} />
          <TermsParagraph i18nKey="agreementToOriginateAchEntries.paragraph2" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={20}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="complianceWithRulesAndLaws.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="complianceWithRulesAndLaws.paragraph1" t={t} />
          <TermsParagraph i18nKey="complianceWithRulesAndLaws.paragraph2" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={21}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="typesAndTimingOfEntries.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="typesAndTimingOfEntries.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={22}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="securityProceduresAndDataSecurity.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="securityProceduresAndDataSecurity.paragraph1" t={t} />
          <TermsParagraph i18nKey="securityProceduresAndDataSecurity.paragraph2" t={t} />
          <TermsParagraph i18nKey="securityProceduresAndDataSecurity.paragraph3" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={23}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="yourRepresentations.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="yourRepresentations.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={24}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation
                  i18nKey="ourObligationsWarrantiesAndRepresentations.title"
                  t={t}
                />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="ourObligationsWarrantiesAndRepresentations.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={25}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="paymentForEntriesFundingAccount.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="paymentForEntriesFundingAccount.paragraph1" t={t} />
          <TermsParagraph i18nKey="paymentForEntriesFundingAccount.paragraph2" t={t} />
          <TermsParagraph i18nKey="paymentForEntriesFundingAccount.paragraph3" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={26}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="authorizationOfAchEntries.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="authorizationOfAchEntries.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={27}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="cancellationAmendmentOrRejectionOfEntries.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="cancellationAmendmentOrRejectionOfEntries.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={28}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation
                  i18nKey="returnedEntriesNotificationsOfChangeAndReversals.title"
                  t={t}
                />
              </h2>
            </li>
          </ol>
          <TermsParagraph
            i18nKey="returnedEntriesNotificationsOfChangeAndReversals.paragraph1"
            t={t}
          />
          <TermsParagraph
            i18nKey="returnedEntriesNotificationsOfChangeAndReversals.paragraph2"
            t={t}
          />
          <ol className="c3 lst-kix_list_2-0" start={10}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="fundsTransfers.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="fundsTransfers.paragraph1" t={t} />
          <TermsParagraph i18nKey="fundsTransfers.paragraph2" t={t} />
          <TermsParagraph i18nKey="fundsTransfers.paragraph3" t={t} />
          <TermsParagraph i18nKey="fundsTransfers.paragraph4" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={11}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="feesAndOtherCharges.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="feesAndOtherCharges.paragraph1" t={t} />
          <TermsParagraph i18nKey="feesAndOtherCharges.paragraph2" t={t} />
          <TermsParagraph i18nKey="feesAndOtherCharges.paragraph3" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={12}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="additionalServices.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="additionalServices.paragraph1" t={t} />
          <TermsParagraph i18nKey="additionalServices.paragraph2" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={13}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="thirdPartyServiceProviders.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="thirdPartyServiceProviders.paragraph1" t={t} />
          <TermsParagraph i18nKey="thirdPartyServiceProviders.paragraph2" t={t} />
          <TermsParagraph i18nKey="thirdPartyServiceProviders.paragraph3" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={14}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="useOfInformation.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="useOfInformation.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={15}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="inactiveAccounts.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="inactiveAccounts.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={16}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="closingYourBusinessAccount.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="closingYourBusinessAccount.paragraph1" t={t} />
          <TermsParagraph i18nKey="closingYourBusinessAccount.paragraph2" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={17}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="prohibitedActivities.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="prohibitedActivities.paragraph1.text" t={t} />
          <ul className="c3 lst-kix_list_1-0">
            <li className="c7 li-bullet-1">
              <TermsTranslation i18nKey="prohibitedActivities.paragraph1.item1" t={t} />
            </li>
            <li className="c7 li-bullet-1">
              <TermsTranslation i18nKey="prohibitedActivities.paragraph1.item2" t={t} />
            </li>
            <li className="c7 li-bullet-1">
              <TermsTranslation i18nKey="prohibitedActivities.paragraph1.item3" t={t} />
            </li>
            <li className="c7 li-bullet-1">
              <TermsTranslation i18nKey="prohibitedActivities.paragraph1.item4" t={t} />
            </li>
            <li className="c7 li-bullet-1">
              <TermsTranslation i18nKey="prohibitedActivities.paragraph1.item5" t={t} />
            </li>
            <li className="c7 li-bullet-1">
              <TermsTranslation i18nKey="prohibitedActivities.paragraph1.item6" t={t} />
            </li>
            <li className="c7 li-bullet-1">
              <TermsTranslation i18nKey="prohibitedActivities.paragraph1.item7" t={t} />
            </li>
            <li className="c7 li-bullet-1">
              <TermsTranslation i18nKey="prohibitedActivities.paragraph1.item8" t={t} />
            </li>
            <li className="c7 li-bullet-1">
              <TermsTranslation i18nKey="prohibitedActivities.paragraph1.item9" t={t} />
            </li>
            <li className="c7 li-bullet-1">
              <TermsTranslation i18nKey="prohibitedActivities.paragraph1.item10" t={t} />
            </li>
            <li className="c7 li-bullet-1">
              <TermsTranslation i18nKey="prohibitedActivities.paragraph1.item11" t={t} />
            </li>
            <li className="c7 li-bullet-1">
              <TermsTranslation i18nKey="prohibitedActivities.paragraph1.item12" t={t} />
            </li>
          </ul>
          <br />
          <TermsParagraph i18nKey="prohibitedActivities.paragraph2.text" t={t} />
          <ul className="c3 lst-kix_list_1-0">
            <li className="c7 li-bullet-1">
              <TermsTranslation i18nKey="prohibitedActivities.paragraph2.item1" t={t} />
            </li>
            <li className="c7 li-bullet-1">
              <TermsTranslation i18nKey="prohibitedActivities.paragraph2.item2" t={t} />
            </li>
            <li className="c7 li-bullet-1">
              <TermsTranslation i18nKey="prohibitedActivities.paragraph2.item3" t={t} />
            </li>
            <li className="c7 li-bullet-1">
              <TermsTranslation i18nKey="prohibitedActivities.paragraph2.item4" t={t} />
            </li>
          </ul>
          <br />
          <ol className="c3 lst-kix_list_2-0" start={18}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation
                  i18nKey="terminationAndSuspensionOurRightToFreezeFunds.title"
                  t={t}
                />
              </h1>
            </li>
          </ol>
          <TermsParagraph
            i18nKey="terminationAndSuspensionOurRightToFreezeFunds.paragraph1"
            t={t}
          />
          <TermsParagraph
            i18nKey="terminationAndSuspensionOurRightToFreezeFunds.paragraph2"
            t={t}
          />
          <ol className="c3 lst-kix_list_2-0" start={19}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="governingLaw.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="governingLaw.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={20}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="arbitration.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="arbitration.paragraph1" t={t} />
          <TermsParagraph i18nKey="arbitration.paragraph2" t={t} />
          <TermsParagraph i18nKey="arbitration.paragraph3" t={t} />
          <TermsParagraph i18nKey="arbitration.paragraph4" t={t} />
          <TermsParagraph i18nKey="arbitration.paragraph5" t={t} />
          <TermsParagraph i18nKey="arbitration.paragraph6" t={t} />
          <TermsParagraph i18nKey="arbitration.paragraph7" t={t} />
          <TermsParagraph i18nKey="arbitration.paragraph8" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={21}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation
                  i18nKey="limitationsOfLiabilityAndDisclaimerOfWarrantiesWaiverOfClaims.title"
                  t={t}
                />
              </h1>
            </li>
          </ol>
          <TermsParagraph
            i18nKey="limitationsOfLiabilityAndDisclaimerOfWarrantiesWaiverOfClaims.paragraph1"
            t={t}
          />
          <TermsParagraph
            i18nKey="limitationsOfLiabilityAndDisclaimerOfWarrantiesWaiverOfClaims.paragraph2"
            t={t}
          />
          <TermsParagraph
            i18nKey="limitationsOfLiabilityAndDisclaimerOfWarrantiesWaiverOfClaims.paragraph3"
            t={t}
          />
          <TermsParagraph
            i18nKey="limitationsOfLiabilityAndDisclaimerOfWarrantiesWaiverOfClaims.paragraph4"
            t={t}
          />
          <ol className="c3 lst-kix_list_2-0" start={22}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="indemnification.title" t={t} />
              </h1>
            </li>
          </ol>
          <TermsParagraph i18nKey="indemnification.paragraph1" t={t} />
          <TermsParagraph i18nKey="indemnification.paragraph2" t={t} />
          <ol className="c3 lst-kix_list_2-0" start={23}>
            <li className="c11 li-bullet-0">
              <h1>
                <TermsTranslation i18nKey="miscellaneous.title" t={t} />
              </h1>
            </li>
          </ol>
          <ol className="c3 lst-kix_list_2-1 start" start={1}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="miscellaneous.severability.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="miscellaneous.severability.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={2}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="miscellaneous.noWaiver.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="miscellaneous.noWaiver.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={3}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="miscellaneous.headings.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="miscellaneous.headings.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={4}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="miscellaneous.assignment.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="miscellaneous.assignment.paragraph1" t={t} />
          <ol className="c3 lst-kix_list_2-1" start={5}>
            <li className="c12 li-bullet-0">
              <h2>
                <TermsTranslation i18nKey="miscellaneous.entireAgreement.title" t={t} />
              </h2>
            </li>
          </ol>
          <TermsParagraph i18nKey="miscellaneous.entireAgreement.paragraph1" t={t} />
          <br />
          <TermsParagraph i18nKey="acceptStatement" t={t} />
          <hr />
          <TermsTranslation i18nKey="appendix.title" t={t} />
          <TermsParagraph i18nKey="appendix.subTitle" t={t} />
          <TermsParagraph i18nKey="appendix.paragraph1" t={t} />
          <TermsParagraph i18nKey="appendix.paragraph2.text" t={t} />
          <ul className="c3 lst-kix_list_1-0">
            <li className="c19 li-bullet-1">
              <TermsTranslation i18nKey="appendix.paragraph2.item1" t={t} />
            </li>
            <li className="c19 li-bullet-1">
              <TermsTranslation i18nKey="appendix.paragraph2.item2" t={t} />
            </li>
            <li className="c19 li-bullet-1">
              <TermsTranslation i18nKey="appendix.paragraph2.item3" t={t} />
            </li>
          </ul>
          <br />
          <TermsParagraph i18nKey="appendix.paragraph3" t={t} />
        </div>
        <hr className="m-0" />
        <div className="terms-footer">
          {showCta && (
            <div className="d-flex align-items-center justify-content-end flex-gap-3">
              <div className="terms-footer-text">
                <Translation
                  components={[
                    <Link data-testid="scroll-button" href="#" onClick={scrollToBottom} />,
                  ]}
                  i18nKey="scroll"
                  translationFunc={t}
                />
              </div>
              <Button disabled={!scrolledToBottom} rounded type="submit">
                {t("cta")}
              </Button>
            </div>
          )}
        </div>
      </form>
    </Paper>
  );
};
