import env from "./env.json";
import deepmerge from "deepmerge";

interface ConfigSet {
  default: any;
  local: any;
  dev: any;
  prod: any;
}

interface Environment {
  env: keyof ConfigSet;
  release: string;
}
const config: ConfigSet = {
  default: {
    brandName: "Flume",
    landing: "https://flumepay.com",
    support: {
      email: "wexselectsupport@wexinc.com",
      emailNoDomain: "flumesupport",
      salesCalendar: "https://calendly.com/flumepay",
      salesEmail: "flumesales@wexinc.com",
      phone: "18664635863",
      phoneNumber: "1-866-463-5863",
      phoneVanity: "1-866-GO-FLUME",
      faq: "https://wexincsupport.zendesk.com/hc/en-us",
      licensing: "https://www.wexinc.com/payment_delivery/state-licensing",
      privacyPolicy: "https://www.wexinc.com/privacy-policy/",
    },
    appStore: "https://apps.apple.com/us/app/flumepay/id1599294133",
    stripe: "https://dashboard.stripe.com/login",
    idleTimeout: 60,
    idlePendingTimeout: 2,
    httpClient: {
      contentType: "application/json",
      baseUri: "http://localhost:3003",
    },
    auth: {
      redirectUri: window.location.origin + "/login/callback",
      logo: "/img/wex-select-primary-logo.svg",
      features: {
        registration: true,
      },
      authParams: {
        scopes: ["openid", "profile", "email", "offline_access"],
      },
      i18n: {
        en: {
          // Sign in title
          "primaryauth.title": "Log in",
          // Sign in CTA
          "primaryauth.submit": "Log in",
          // Help logging in
          needhelp: "Need help logging in?",
          // Sign up title
          "registration.form.title": "Create a free business account",
          // Sign up CTA
          "registration.form.submit": "Continue",
          // Return to sign in
          goback: "Back to log in",
          // GLOBAL ERRORS
          "error.username.required": "Please enter an email",
          // ERROR MESSAGE TRANSLATIONS
          "errors.E0000207":
            "The email and/or the password you entered is incorrect. Please try again.",
          // REQUIRED COURAGE COMPONENTS
          "model.validation.field.username": "Please check your email",
          // Username & Password
          "primaryauth.username.placeholder": "Email",
          // Forgot Password
          "password.forgot.email.or.username.placeholder": "Email",
          "password.forgot.email.or.username.tooltip": "Email",
          "password.forgot.sendEmail": "Send reset email",
          // Reset Password
          "password.complexity.no_username": "no parts of your email",
          "password.complexity.no_username.description": "No parts of your email",
          // Unlock Account
          "account.unlock.email.or.username.placeholder": "Email",
          "account.unlock.email.or.username.tooltip": "Email",
          "account.unlock.unlocked.desc": "You can log in using your existing email and password.",
          // Registration Default text
          "registration.passwordComplexity.excludeUsername": "Does not contain part of email",
          // Password
          "idx.recovery.completed":
            "You can now sign in with your existing email and new password.",
          // Self Service Registration Form
          "oie.selfservice.unlock_user.success.message":
            "You can log in using your existing email and password.",
          // Custom Identifiers
          "custom.identifiers.userNotResolved":
            "Can't identify your account with {0}. Enter your email.",
          "oie.enroll.switch.authenticator": "Return to security methods",
          "oie.webauthn.label": "Biometric",
          "oie.webauthn.description":
            "Use biometrics (Windows Hello, Face ID, Touch ID, etc.) to sign in.",
          "oie.enroll.webauthn.title": "Set up biometric authenticator",
          "oie.enroll.webauthn.instructions":
            "You will be prompted to register a biometric authenticator (Windows Hello, Touch ID, Face ID, etc.). Follow the instructions to complete set up.",
          "oie.webauthn.error.not.supported":
            "Biometric authenticator is not supported on this browser.",
          "oie.verify.webauth.title": "Verify with Biometric Authenticator",
          "oie.verify.webauthn.instructions":
            "You will be prompted to use biometric verification (Windows Hello, Face ID, Touch ID, etc.). Follow the instructions to complete verification.",
          "idx.enter.otp.in.original.tab":
            "Enter the code in your original authentication location.",
          "socialauth.divider.text": "Or sign up or log in with",
          "authbutton.divider.text": "Or sign up or log in with",
          "customauth.sign.in.with.label": "Log in with {0}",
          "socialauth.facebook.label": "Log in with Facebook",
          "socialauth.google.label": "Log in with Google",
          "socialauth.apple.label": "Log in with Apple",
          "registration.signup.label":
            "Don't have an account?  Sign up with a log in option above or",
          "registration.signup.text": "Create an account with your email",
          "oie.registration.form.title": "Create an account with your email",
        },
      },
    },
    iovation: {
      url: "https://ci-mpsnare.iovation.com/snare.js",
    },
    firebase: {
      apiKey: "AIzaSyBzuZm-Rcu2u8cacx4L21_RPCAlt3_HH08",
      authDomain: "wex-inno-flume.firebaseapp.com",
      projectId: "wex-inno-flume",
      storageBucket: "wex-inno-flume.appspot.com",
      messagingSenderId: "955959038598",
      appId: "1:955959038598:web:3176e43374e630c94c7707",
      measurementId: "G-62PFW71406",
    },
    document: {
      maxSize: 1024 * 1024 * 10,
      allowedTypes: ["image/png", "image/jpeg", "application/pdf", "text/plain", "text/csv"],
    },
    currentPromo: "projects",
    fuel: {
      wexFleetBusinessName: "WEX Fleet",
    },
  },
  local: {
    auth: {
      baseUrl: "https://login.flume-dev.com",
      issuer: "https://login.flume-dev.com/oauth2/aus8codq652oXqiFp1d7",
      clientId: "0oa21xbzulQYL7Vnl1d7",
    },
  },
  dev: {
    httpClient: {
      baseUri: "https://edge-service.flume-dev.com",
    },
    auth: {
      baseUrl: "https://login.flume-dev.com",
      issuer: "https://login.flume-dev.com/oauth2/aus8codq652oXqiFp1d7",
      clientId: "0oa21xfeyyrEOXUlN1d7",
    },
    firebase: {
      apiKey: "AIzaSyBzuZm-Rcu2u8cacx4L21_RPCAlt3_HH08",
      authDomain: "wex-inno-flume.firebaseapp.com",
      projectId: "wex-inno-flume",
      storageBucket: "wex-inno-flume.appspot.com",
      messagingSenderId: "955959038598",
      appId: "1:955959038598:web:59d26b2615c13ce74c7707",
      measurementId: "G-0L287XMVHE",
    },
    pendo: {
      apiKey: "a4039976-86a3-48ee-5aeb-34989af11def",
    },
  },
  prod: {
    idleTimeout: 15,
    httpClient: {
      baseUri: "https://edge-service.flumepay.com",
    },
    auth: {
      baseUrl: "https://login.flumepay.com",
      issuer: "https://login.flumepay.com/oauth2/aus5vb9rrmVtfpaAd697",
      clientId: "0oa97ndj17DTydwXs696",
    },
    facebook: {
      pixelID: "683024403272747",
      options: {},
    },
    pendo: {
      apiKey: "a4039976-86a3-48ee-5aeb-34989af11def",
    },
    firebase: {
      apiKey: "AIzaSyBzuZm-Rcu2u8cacx4L21_RPCAlt3_HH08",
      authDomain: "wex-inno-flume.firebaseapp.com",
      projectId: "wex-inno-flume",
      storageBucket: "wex-inno-flume.appspot.com",
      messagingSenderId: "955959038598",
      appId: "1:955959038598:web:f9b4ae0de7d7bc6f4c7707",
      measurementId: "G-FGRGRYRBCY",
    },
    iovation: {
      url: "https://mpsnare.iesnare.com/snare.js",
    },
  },
};

const cfg = deepmerge(deepmerge(config.default, config[(env as Environment).env]), env) as any;

export const isDeployed = () => cfg.env === "prod" || cfg.env === "dev";

export const isProd = () => cfg.env === "prod";

export default cfg;
