import { Components } from "@mui/material/styles";
import colors from "../theme/colors.module.scss";

export const chipTheme: Components = {
  MuiChip: {
    defaultProps: {
      size: "small",
      color: "primary",
    },
    styleOverrides: {
      root: {
        height: "unset",
        padding: "0px 8px",
        fontFamily: "'Inter', 'Roboto', sans-serif",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "24px",
        backgroundColor: colors["neutral-10"],
        color: colors["neutral-70"],
      },
      sizeSmall: {
        borderRadius: "4px",
        fontSize: "13px",
        lineHeight: "24px",
        padding: "2px 8px",
      },
      label: {
        padding: "0",
        color: "inherit",
      },
      outlined: {
        borderColor: "inherit",
        backgroundColor: "transparent",
      },
      rounded: {
        borderRadius: "21px",
        lineHeight: "16px",
      },
      colorPrimary: {
        backgroundColor: colors["primary-50"],
        color: colors["neutral-00"],
      },
      colorError: {
        backgroundColor: colors["critical-30"],
        color: colors["critical-80"],
      },
      colorWarning: {
        backgroundColor: colors["warning-20"],
        color: colors["warning-80"],
      },
      colorInfo: {
        backgroundColor: colors["info-20"],
        color: colors["info-80"],
      },
      colorSuccess: {
        backgroundColor: colors["green-20"],
        color: colors["green-70"],
      },
      // Typescript error but shows up correctly. If type can be updated later, that'd be nice.
      colorTertiary: {
        backgroundColor: colors["purple-20"],
        color: colors["purple-70"],
      },
      icon: {
        marginLeft: 0,
        marginRight: "4px",
        fontSize: "inherit",
      },
      deleteIcon: {
        marginLeft: "4px",
        marginRight: 0,
      },
    },
  },
};
