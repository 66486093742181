import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import get from "lodash.get";
import set from "lodash.set";
import {
  FundRequest,
  PaymentService,
  PaymentHistoryResponse,
  Payment,
  Project,
  UpdatePaymentRequest,
  ManualPaymentRequest,
  InvoiceService,
  DeleteResponse,
} from "../../generated/openapi";
import { PaymentRequest } from "../../generated/openapi/models/PaymentRequest";
import { PaymentResponse } from "../../generated/openapi/models/PaymentResponse";
import { logRejectedThunk } from "../../sentry";
import { RootState, StatefulEntity } from "../../store";
import { returnWithErrorWrap } from "../../store/error";
import { getInvoice } from "./invoice/invoiceSlice";
import { Writable, pending, update, error, remove } from "../../store/reducer";
import { convertToAPI, invoiceToAPI } from "./invoice/utils";

export const PAYMENT_MAXIMUM_DOCS_NUMBER_ERROR =
  "number of attached payment documents exceeds maximum allowed";

export enum PaymentsType {
  TRANSACTION = "transaction",
  DUE = "payment-due",
  FLEET = "fleet",
  FLEET_AUTH = "fleet_auth",
}

export const sendPayment = createAsyncThunk<
  PaymentResponse,
  { data: PaymentRequest; id: string },
  {}
>("payment/send", async (arg: { data: PaymentRequest; id: string }, thunk) => {
  const data = {
    ...arg.data,
    amount: convertToAPI(arg.data.amount),
    invoice: invoiceToAPI(arg.data.invoice),
  };
  return returnWithErrorWrap(() => PaymentService.payWithPayee(data, arg.id), thunk);
});

export const sendManualPayment = createAsyncThunk<
  Payment,
  { businessAccountId: string; invoiceId: string; payment: ManualPaymentRequest },
  {}
>("payment/manual/create", async (arg, thunk) => {
  arg.payment.amount = Math.round(arg.payment.amount * 100);
  const payment = await InvoiceService.postManualPayment(
    arg.payment,
    arg.businessAccountId,
    arg.invoiceId
  );
  thunk.dispatch(
    getInvoice({ businessId: arg.businessAccountId, invoiceId: arg.invoiceId, type: "invoice" })
  );
  return payment;
});
export const updateManualPayment = createAsyncThunk<
  Payment,
  {
    businessAccountId: string;
    invoiceId: string;
    payment: ManualPaymentRequest;
    paymentId: string;
  },
  {}
>("payment/manual/update", async (arg, thunk) => {
  arg.payment.amount = Math.round(arg.payment.amount * 100);
  const payment = await InvoiceService.putManualPayment(
    arg.payment,
    arg.businessAccountId,
    arg.invoiceId,
    arg.paymentId
  );
  thunk.dispatch(
    getInvoice({ businessId: arg.businessAccountId, invoiceId: arg.invoiceId, type: "invoice" })
  );
  return payment;
});
export const deleteManualPayment = createAsyncThunk<
  DeleteResponse,
  { businessAccountId: string; invoiceId: string; paymentId: string },
  {}
>("payment/manual/delete", async (arg, thunk) => {
  const response = await InvoiceService.deleteManualPayment(
    arg.businessAccountId,
    arg.invoiceId,
    arg.paymentId
  );
  thunk.dispatch(
    getInvoice({ businessId: arg.businessAccountId, invoiceId: arg.invoiceId, type: "invoice" })
  );
  return response;
});

export const clearCreatedPaymentResponse = createAsyncThunk(
  "payment/clearCreatedResponse",
  async () => {}
);

interface GetPaymentsArgs {
  id: string;
  limit?: number;
  page?: number;
  sort?: string;
  direction?: string;
  projectId?: string;
  methods?: string;
  paytypes?: string;
  statuses?: string;
  amounts?: string;
  dates?: string;
  fleetcardId?: string;
  payorId?: string;
  payeeId?: string;
  payeeName?: string;
}
export const getPayments = createAsyncThunk<PaymentHistoryResponse, GetPaymentsArgs, {}>(
  "payment/get",
  async (arg, thunk) =>
    returnWithErrorWrap(
      async () =>
        await PaymentService.getPaymentHistory(
          arg.id,
          arg.limit,
          arg.page,
          arg.sort,
          arg.direction,
          arg.projectId,
          arg.methods,
          arg.paytypes,
          arg.statuses,
          arg.amounts,
          arg.dates,
          arg.fleetcardId,
          arg.payorId,
          arg.payeeId,
          arg.payeeName
        ),
      thunk
    )
);

/**
 * Use this getter when using the different stores for transaction types.
 */
export const getPaymentsByType = createAsyncThunk<
  PaymentHistoryResponse,
  { type: PaymentsType; args: GetPaymentsArgs },
  {}
>("payments/getByType", async (arg, thunk) => {
  return returnWithErrorWrap(
    async () =>
      await PaymentService.getPaymentHistory(
        arg.args.id,
        arg.args.limit,
        arg.args.page,
        arg.args.sort,
        arg.args.direction,
        arg.args.projectId,
        arg.args.methods,
        arg.args.paytypes || getFilterForType(arg.type),
        arg.args.statuses,
        arg.args.amounts,
        arg.args.dates,
        arg.args.fleetcardId,
        arg.args.payorId,
        arg.args.payeeId,
        arg.args.payeeName
      ),
    thunk
  );
});

export const getFilterForType = (type: PaymentsType) => {
  switch (type) {
    case PaymentsType.TRANSACTION:
      return "payor,payee,fund,withdraw,subscription,reward,card_payout";
    case PaymentsType.DUE:
      return "ap";
    case PaymentsType.FLEET:
      return "fleet";
    default:
      return "";
  }
};

export const getPayment = createAsyncThunk<Payment, string, {}>(
  "payment/get/byid",
  async (arg, thunk) => returnWithErrorWrap(async () => await PaymentService.getPayment(arg), thunk)
);

export const fundAccount = createAsyncThunk<PaymentResponse, { id: string; data: FundRequest }, {}>(
  "payment/transfer/fund",
  async (arg, thunk) => {
    const data = { ...arg.data, amount: convertToAPI(arg.data.amount) };
    return returnWithErrorWrap(() => PaymentService.fund(arg.id, data), thunk);
  }
);
export const withdrawFromAccount = createAsyncThunk<
  PaymentResponse,
  { id: string; data: FundRequest },
  {}
>("payment/transfer/withdraw", async (arg, thunk) => {
  const data = { ...arg.data, amount: convertToAPI(arg.data.amount) };
  return returnWithErrorWrap(() => PaymentService.withdraw(arg.id, data), thunk);
});

export const updatePayment = createAsyncThunk<
  Payment,
  { id: string; data: UpdatePaymentRequest },
  {}
>("payment/update", async (arg, thunk) => {
  const paymentResponse = await returnWithErrorWrap(
    () => PaymentService.updatePayment(arg.data, arg.id),
    thunk
  );

  if (arg.data.invoice_id) {
    thunk.dispatch(
      getInvoice({
        businessId: arg.data.business_account_id,
        invoiceId: arg.data.invoice_id,
        type: "invoice",
      })
    );
  }
  return paymentResponse;
});

export const addProjectToPayment = createAsyncThunk<
  Payment,
  { businessAccountId: string; paymentId: string; project: Project },
  {}
>(
  "payment/project/add",
  async (args, thunk) =>
    await returnWithErrorWrap(
      () => PaymentService.paymentProject(args.businessAccountId, args.paymentId, args.project),
      thunk
    )
);
export const removeProjectFromPayment = createAsyncThunk<
  DeleteResponse,
  { businessAccountId: string; paymentId: string; projectId: string },
  {}
>(
  "payment/project/remove",
  async (args, thunk) =>
    await returnWithErrorWrap(
      () =>
        PaymentService.deletePaymentProject(args.businessAccountId, args.paymentId, args.projectId),
      thunk
    )
);

export const voidPayment = createAsyncThunk<Payment, { id: string; businessAccountId: string }, {}>(
  "payment/check/void",
  async (arg, thunk) =>
    await returnWithErrorWrap(
      () => PaymentService.voidPayment(arg.businessAccountId, arg.id),
      thunk
    )
);

export const selectLatestPaymentState = (state: RootState) => state.payment.payment;
export const selectPaymentCreateResponse = (state: RootState) => state.payment.paymentResponse;

export const selectPaymentById = (id: string) =>
  createSelector(
    (state: RootState) => state.payment.payments.data.payments,
    (payments) => payments?.find((payment) => payment.id === id)
  );
export const selectTransactionById = (id: string) =>
  createSelector(
    (state: RootState) => state.payment.transactions.data.payments,
    (payments) => payments?.find((payment) => payment.id === id)
  );

export const createSelectPaymentState = (
  type: PaymentsType,
  projectId?: string
): ((state: RootState) => StatefulEntity<PaymentHistoryResponse>) => {
  switch (type) {
    case PaymentsType.TRANSACTION:
      if (!projectId) return (state: RootState) => state.payment.transactions;
      return (state: RootState) => {
        if (!state.payment.projectTransactions[projectId])
          return { ...createInitialPaymentsState() };
        return state.payment.projectTransactions[projectId];
      };
    case PaymentsType.DUE:
      if (!projectId) return (state: RootState) => state.payment.paymentsDue;
      return (state: RootState) => {
        if (!state.payment.projectPaymentsDue[projectId])
          return { ...createInitialPaymentsState() };
        return state.payment.projectPaymentsDue[projectId];
      };
    default:
      return (state: RootState) => state.payment.payments;
  }
};

export const getStoreKey = (type: PaymentsType, projectId?: string) => {
  switch (type) {
    case PaymentsType.TRANSACTION:
      return projectId ? `projectTransactions.${projectId}` : "transactions";
    case PaymentsType.DUE:
      return projectId ? `projectPaymentsDue.${projectId}` : "paymentsDue";
    default:
      return "payments";
  }
};

export const handlePaymentResponse = (
  payment: Payment,
  state: PaymentsState,
  onAction: (
    state: StatefulEntity<PaymentHistoryResponse>
  ) => StatefulEntity<PaymentHistoryResponse>
) => {
  state.payments = onAction(state.payments);
  if (
    payment.payment_type !== Payment.payment_type.AR &&
    payment.payment_type !== Payment.payment_type.AP
  ) {
    state.transactions = onAction(state.transactions);
    if (payment.projects?.length > 0) {
      payment.projects.forEach((project) => {
        state.projectTransactions[project?.id] = onAction(
          state.projectTransactions[project?.id] || { ...createInitialPaymentsState() }
        );
      });
    }
  }
  if (payment.payment_type === Payment.payment_type.AP) {
    state.paymentsDue = onAction(state.paymentsDue);
    if (payment.projects?.length > 0) {
      payment.projects.forEach((project) => {
        state.projectPaymentsDue[project?.id] = onAction(
          state.projectPaymentsDue[project?.id] || { ...createInitialPaymentsState() }
        );
      });
    }
  }
};

export type ProjectPaymentsState = Record<"string", StatefulEntity<PaymentHistoryResponse>>;
export type PaymentsState = {
  payments: StatefulEntity<PaymentHistoryResponse>;
  transactions: StatefulEntity<PaymentHistoryResponse>;
  paymentsDue: StatefulEntity<PaymentHistoryResponse>;
  projectTransactions: ProjectPaymentsState;
  projectPaymentsDue: ProjectPaymentsState;
};

const createInitialPaymentsState = (): StatefulEntity<PaymentHistoryResponse> => ({
  data: { additional_pages: false, payments: [] },
  empty: true,
  loading: false,
  fulfilled: null,
});

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    payment: { data: null, empty: true, loading: false } as StatefulEntity<Payment>,
    paymentResponse: { data: null, empty: true, loading: false } as StatefulEntity<PaymentResponse>,
    payments: { ...createInitialPaymentsState() },
    transactions: { ...createInitialPaymentsState() },
    paymentsDue: { ...createInitialPaymentsState() },
    projectTransactions: {} as ProjectPaymentsState,
    projectPaymentsDue: {} as ProjectPaymentsState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendPayment.pending, (state) => {
      state.paymentResponse.loading = true;
      state.paymentResponse.error = null;
    });
    builder.addCase(sendPayment.fulfilled, (state, action) => {
      state.paymentResponse.data = action.payload;
      state.paymentResponse.empty = false;
      state.paymentResponse.loading = false;
      state.paymentResponse.fulfilled = Date.now();

      const payment = action.payload.recent_payments.find(
        (payment) => action.payload.id === payment.id
      );
      state.payment.data = payment;
      state.payment.empty = false;
      state.payment.loading = false;
      state.payment.error = null;
      handlePaymentResponse(payment, state, (state) => {
        const idx = state.data.payments.findIndex((pmt) => payment.payment_id === pmt.payment_id);
        const updatedState = { ...state };
        updatedState.data.payments.splice(idx, idx > -1 ? 1 : 0, payment);
        updatedState.empty = false;
        return updatedState;
      });
    });
    builder.addCase(sendPayment.rejected, (state, action) => {
      state.paymentResponse.loading = false;
      state.paymentResponse.error = action.payload;
      logRejectedThunk(state, action);
    });

    builder.addCase(clearCreatedPaymentResponse.fulfilled, (state) => {
      state.paymentResponse.data = null;
      state.paymentResponse.empty = true;
      state.paymentResponse.loading = false;
      state.paymentResponse.error = null;
      state.paymentResponse.fulfilled = Date.now();
    });

    builder.addCase(getPayments.pending, (state) => {
      state.payments.loading = true;
      state.payments.error = null;
    });
    builder.addCase(getPayments.fulfilled, (state, action) => {
      state.payments.loading = false;
      state.payments.data = action.payload.payments
        ? action.payload
        : { ...action.payload, payments: [] };
      state.payments.empty = !action.payload.payments || action.payload.payments.length === 0;
      state.payments.fulfilled = Date.now();
    });
    builder.addCase(getPayments.rejected, (state, action) => {
      state.payments.loading = false;
      state.payments.error = action.payload;
      logRejectedThunk(state, action);
    });

    builder.addCase(getPaymentsByType.pending, (state, action) => {
      const storeKey = getStoreKey(action.meta.arg.type, action.meta.arg.args.projectId);
      if (get(state, storeKey)) {
        set(state, `${storeKey}.loading`, true);
        set(state, `${storeKey}.error`, null);
      } else {
        set(state, storeKey, { ...createInitialPaymentsState() });
      }
    });
    builder.addCase(getPaymentsByType.fulfilled, (state, action) => {
      const storeKey = getStoreKey(action.meta.arg.type, action.meta.arg.args.projectId);
      const data = action.payload.payments ? action.payload : { ...action.payload, payments: [] };
      const isEmpty = !action.payload.payments || action.payload.payments.length === 0;
      set(state, `${storeKey}.loading`, false);
      set(state, `${storeKey}.data`, data);
      set(state, `${storeKey}.empty`, isEmpty);
      set(state, `${storeKey}.fulfilled`, Date.now());
    });
    builder.addCase(getPaymentsByType.rejected, (state, action) => {
      const storeKey = getStoreKey(action.meta.arg.type, action.meta.arg.args.projectId);
      set(state, `${storeKey}.loading`, false);
      set(state, `${storeKey}.error`, action.payload);
      logRejectedThunk(state, action);
    });

    builder.addCase(getPayment.pending, (state) => {
      state.payment.loading = true;
      state.payment.error = null;
    });

    builder.addCase(getPayment.fulfilled, (state, action) => {
      state.payment.data = action.payload;
      state.payment.loading = false;
      state.payment.error = null;
      state.payment.fulfilled = Date.now();
      handlePaymentResponse(action.payload, state, (state) => {
        const idx = state.data.payments.findIndex(
          (pmt) => action.payload.payment_id === pmt.payment_id
        );
        const updatedState = { ...state };
        updatedState.data.payments.splice(idx, idx > -1 ? 1 : 0, action.payload);
        updatedState.empty = updatedState.data.payments.length === 0;
        return updatedState;
      });
    });

    builder.addCase(getPayment.rejected, (state, action) => {
      state.payment.loading = false;
      state.payment.error = action.payload;
      logRejectedThunk(state, action);
    });

    builder.addCase(fundAccount.pending, (state) => {
      state.paymentResponse.loading = true;
      state.paymentResponse.error = null;
    });
    builder.addCase(fundAccount.fulfilled, (state, action) => {
      state.paymentResponse.loading = false;
      state.paymentResponse.data = action.payload;
      state.paymentResponse.empty = false;
      state.paymentResponse.fulfilled = Date.now();

      const payment = action.payload.recent_payments.find(
        (payment) => action.payload.id === payment.id
      );
      state.payment.data = payment;
      state.payment.empty = false;
      state.payment.loading = false;
      state.payment.error = null;

      state.payments.data.payments.unshift(payment);
      state.payments.empty = false;
      state.transactions.data.payments.unshift(payment);
      state.transactions.empty = false;
    });
    builder.addCase(fundAccount.rejected, (state, action) => {
      state.paymentResponse.loading = false;
      state.paymentResponse.error = action.payload;
      logRejectedThunk(state, action);
    });

    builder.addCase(withdrawFromAccount.pending, (state) => {
      state.paymentResponse.loading = true;
      state.paymentResponse.error = null;
    });
    builder.addCase(withdrawFromAccount.fulfilled, (state, action) => {
      state.paymentResponse.loading = false;
      state.paymentResponse.data = action.payload;
      state.paymentResponse.empty = false;
      state.paymentResponse.fulfilled = Date.now();

      const payment = action.payload.recent_payments.find(
        (payment) => action.payload.id === payment.id
      );
      state.payment.data = payment;
      state.payment.empty = false;
      state.payment.loading = false;
      state.payment.error = null;

      state.payments.data.payments.unshift(payment);
      state.payments.empty = false;
      state.transactions.data.payments.unshift(payment);
      state.transactions.empty = false;
    });
    builder.addCase(withdrawFromAccount.rejected, (state, action) => {
      state.paymentResponse.loading = false;
      state.paymentResponse.error = action.payload;
      logRejectedThunk(state, action);
    });

    builder.addCase(updatePayment.pending, (state) => {
      state.payment.loading = true;
      state.payment.error = null;
    });
    builder.addCase(updatePayment.rejected, (state, action) => {
      state.payment.loading = false;
      state.payment.error = action.payload;
      logRejectedThunk(state, action);
    });
    builder.addCase(updatePayment.fulfilled, (state, action) => {
      state.payment.data = action.payload;
      state.payment.empty = false;
      state.payment.loading = false;
      state.payment.fulfilled = Date.now();

      handlePaymentResponse(action.payload, state, (state) => {
        const idx = state.data.payments.findIndex((item) => item.id === action.payload.id);
        const updatedState = { ...state };
        updatedState.data.payments.splice(idx, idx > -1 ? 1 : 0, action.payload);
        updatedState.empty = updatedState.data.payments.length === 0;
        return updatedState;
      });
    });

    builder.addCase(addProjectToPayment.pending, (state) => {
      state.payment.loading = true;
      state.payment.error = null;
    });
    builder.addCase(addProjectToPayment.fulfilled, (state, action) => {
      state.payment.data = action.payload;
      state.payment.loading = false;
      state.payment.empty = false;
      state.payment.fulfilled = Date.now();

      handlePaymentResponse(action.payload, state, (state) => {
        const idx = state.data.payments.findIndex((item) => item.id === action.payload.id);
        const updatedState = { ...state };
        updatedState.data.payments.splice(idx, idx > -1 ? 1 : 0, action.payload);
        updatedState.empty = updatedState.data.payments.length === 0;
        return updatedState;
      });
    });
    builder.addCase(addProjectToPayment.rejected, (state, action) => {
      state.payment.loading = false;
      state.payment.error = action.payload;
      logRejectedThunk(state, action);
    });

    builder.addCase(removeProjectFromPayment.pending, (state) => {
      state.payment.loading = true;
      state.payment.error = null;
    });
    builder.addCase(removeProjectFromPayment.fulfilled, (state, action) => {
      state.payment.data = action.payload;
      state.payment.loading = false;
      state.payment.empty = false;
      state.payment.fulfilled = Date.now();
      handlePaymentResponse(action.payload, state, (state) => {
        const idx = state.data.payments.findIndex((item) => item.id === action.payload.id);
        const updatedState = { ...state };
        updatedState.data.payments.splice(idx, idx > -1 ? 1 : 0, action.payload);
        updatedState.empty = updatedState.data.payments.length === 0;
        return updatedState;
      });
      const removedProject = action.meta.arg.projectId;
      const projectPaymentsDueIndex = state.projectPaymentsDue?.[
        removedProject
      ]?.data?.payments?.findIndex((item) => item.id === action.payload.id);
      if (projectPaymentsDueIndex > -1) {
        state.projectPaymentsDue[removedProject].data.payments.splice(projectPaymentsDueIndex, 1);
      }
      const projectTransactionsIndex = state.projectTransactions?.[
        removedProject
      ]?.data?.payments?.findIndex((item) => item.id === action.payload.id);
      if (projectTransactionsIndex > -1) {
        state.projectTransactions[removedProject].data.payments.splice(projectTransactionsIndex, 1);
      }
    });
    builder.addCase(removeProjectFromPayment.rejected, (state, action) => {
      state.payment.loading = false;
      state.payment.error = action.payload;
      logRejectedThunk(state, action);
    });

    builder.addCase(sendManualPayment.pending, (state) => pending(state.payments));
    builder.addCase(sendManualPayment.fulfilled, (state, action) =>
      update(state.payments, action, (st) => st.data.payments as Writable[])
    );
    builder.addCase(sendManualPayment.rejected, (state, action) => error(state.payments, action));

    builder.addCase(updateManualPayment.pending, (state) => pending(state.payments));
    builder.addCase(updateManualPayment.fulfilled, (state, action) =>
      update(state.payments, action, (st) => st.data.payments as Writable[])
    );
    builder.addCase(updateManualPayment.rejected, (state, action) => error(state.payments, action));

    builder.addCase(deleteManualPayment.pending, (state) => pending(state.payments));
    builder.addCase(deleteManualPayment.fulfilled, (state, action) =>
      remove(state.payments, action, (st) => st.data.payments as Writable[])
    );
    builder.addCase(deleteManualPayment.rejected, (state, action) => error(state.payments, action));

    builder.addCase(voidPayment.fulfilled, (state, action) =>
      update(state.transactions, action, (st) => st.data.payments as Writable[])
    );
    builder.addCase(voidPayment.rejected, (state, action) => error(state.transactions, action));
  },
});

export default paymentSlice.reducer;
