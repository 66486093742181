import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  EstimateService,
  Invoice,
  InvoicesResponse,
  InvoiceService,
  InvoiceProjectResponse,
  DeleteResponse,
  PaymentResponse,
  SimplePaymentRequest,
  InvoicePaymentService,
  ApiError,
} from "../../../generated/openapi";
import { RootState, StatefulEntity } from "../../../store";
import { returnWithErrorWrap } from "../../../store/error";
import { error, pending, remove, Writable } from "../../../store/reducer";
import { InvoiceType } from "./type";
import { invoiceToAPI, invoiceToUI } from "./utils";

export interface GetInvoicesOptions {
  limit?: number;
  page?: number;
  sort?: string;
  direction?: string;
  status?: string;
  number?: string;
  projectid?: string;
  statuses?: string;
  totalamounts?: string;
}
export const getInvoices = createAsyncThunk<
  InvoicesResponse,
  { businessId: string; type: InvoiceType; options?: GetInvoicesOptions }
>("invoices/get", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      InvoiceService.getInvoicesForBusinessAccount(
        args.businessId,
        args.type,
        args.options.limit,
        args.options.page,
        args.options.sort,
        args.options.direction,
        args.options.status,
        args.options.number,
        args.options.projectid,
        args.options.statuses,
        args.options.totalamounts
      ),
    thunk
  )
);
export const getInvoice = createAsyncThunk<
  Invoice,
  { businessId: string; invoiceId: string; type: InvoiceType }
>("invoice/get", async (args, thunk) =>
  returnWithErrorWrap(
    () => InvoiceService.getInvoice(args.businessId, args.type, args.invoiceId),
    thunk
  )
);
export const createInvoice = createAsyncThunk<
  Invoice,
  { businessId: string; type: InvoiceType; invoice: Invoice }
>("invoice/create", async (args, thunk) => {
  const result = returnWithErrorWrap(
    () => InvoiceService.postInvoice(invoiceToAPI(args.invoice), args.businessId, args.type),
    thunk
  );
  result.then((response) => thunk.dispatch(setCurrentInvoice(invoiceToUI(response))));

  return result;
});
export const updateInvoice = createAsyncThunk<
  Invoice,
  { businessId: string; type: InvoiceType; invoice: Invoice; invoiceId: string }
>("invoice/update", async (args, thunk) => {
  const result = returnWithErrorWrap(
    () =>
      InvoiceService.putInvoice(
        invoiceToAPI(args.invoice),
        args.businessId,
        args.type,
        args.invoiceId
      ),
    thunk,
    (error: ApiError) =>
      error.body.error === "unable to decode error body: EOF"
        ? "Please enter a valid value, emojis are not supported." // TODO: move the logic for this error handling to the level that calls the action
        : error.body.error
  );
  result.then((response) => thunk.dispatch(setCurrentInvoice(invoiceToUI(response))));

  return result;
});
export const updateEstimateStatus = createAsyncThunk<
  Invoice,
  { businessId: string; invoice: Invoice; invoiceId: string }
>("invoice/update/status", async (args, thunk) =>
  returnWithErrorWrap(
    () => EstimateService.putEstimateStatus(args.invoice, args.businessId, args.invoiceId),
    thunk
  )
);
export const deleteInvoice = createAsyncThunk<
  DeleteResponse,
  { businessId: string; type: InvoiceType; invoiceId: string }
>("invoice/delete", async (args, thunk) =>
  returnWithErrorWrap(
    () => InvoiceService.deleteInvoiceV2(args.businessId, args.type, args.invoiceId),
    thunk
  )
);

export const convertToInvoice = createAsyncThunk<
  Invoice,
  { businessId: string; estimateId: string }
>("estimate/convertToInvoice", async (args, thunk) =>
  returnWithErrorWrap(
    () => EstimateService.convertToInvoice(args.businessId, args.estimateId),
    thunk
  )
);
export const resendEstimate = createAsyncThunk<Invoice, { businessId: string; estimateId: string }>(
  "estimate/resend",
  async (args, thunk) =>
    returnWithErrorWrap(
      () => EstimateService.sendEstimateNotification(args.businessId, args.estimateId),
      thunk
    )
);
export const createSimplePayment = createAsyncThunk<
  PaymentResponse,
  { data: SimplePaymentRequest; businessAccountId: string; invoiceId: string },
  {}
>("invoice/simple_payment", async (arg, thunk) => {
  const paymentResponse = await returnWithErrorWrap(
    () =>
      InvoicePaymentService.postSimplePayRequestOnInvoice(
        arg.data,
        arg.businessAccountId,
        arg.invoiceId
      ),
    thunk
  );
  thunk.dispatch(
    getInvoice({ businessId: arg.businessAccountId, invoiceId: arg.invoiceId, type: "invoice" })
  );
  return paymentResponse;
});
export const addProjectToInvoice = createAsyncThunk<
  InvoiceProjectResponse,
  { businessAccountId: string; type: string; invoiceId: string; projectId: string }
>("invoice/project/add", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      InvoiceService.postInvoiceProject(
        args.businessAccountId,
        args.type,
        args.invoiceId,
        args.projectId
      ),
    thunk
  )
);
export const removeProjectFromInvoice = createAsyncThunk<
  DeleteResponse,
  { businessAccountId: string; type: string; invoiceId: string; projectId: string }
>("invoice/project/remove", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      InvoiceService.deleteInvoiceProject(
        args.businessAccountId,
        args.type,
        args.invoiceId,
        args.projectId
      ),
    thunk
  )
);

export const selectCurrentInvoiceState = (state: RootState) => state.invoice.current;
export const selectHasSaveButtonState = (state: RootState) => state.invoice.hasSaveButton;

export const selectInvoicesState = (state: RootState) => state.invoice.invoice;
export const selectEstimatesState = (state: RootState) => state.invoice.estimate;

// This is local data that isn't fetched from the server
export const getCurrentPartialPayment = (state: RootState) =>
  state?.invoice?.localData?.currentPartialPayment;
export const getCurrentInvoice = (state: RootState) => state?.invoice?.localData?.currentInvoice;

const initialState: StatefulEntity<InvoicesResponse> = {
  data: {
    invoices: [],
    additional_pages: false,
  },
  empty: true,
  loading: false,
};

const currentInitialState: StatefulEntity<Invoice> = {
  data: null,
  empty: true,
  loading: false,
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    current: currentInitialState,
    hasSaveButton: true,
    invoice: initialState,
    estimate: initialState,
    localData: {
      currentInvoice: {} as Invoice,
      currentPartialPayment: {} as SimplePaymentRequest,
    },
  },
  reducers: {
    setCurrentInvoice: (state, action) => {
      state.localData.currentInvoice = action.payload;
    },
    clearCurrentLocalInvoice: (state) => {
      state.localData.currentInvoice = null;
    },
    setHasSaveButton: (state, action) => {
      state.hasSaveButton = action.payload;
    },
    setCurrentPartialPayment: (state, action) => {
      state.localData.currentPartialPayment = action.payload;
    },
    clearCurrentPartialPayment: (state) => {
      state.localData.currentPartialPayment = {} as SimplePaymentRequest;
    },
    clearCurrentInvoiceState: (state) => {
      state.current.data = null;
      state.current.empty = true;
      state.current.loading = false;
    },
    setCurrentInvoiceState: (state, action) => {
      state.current.data = action.payload;
      state.current.empty = false;
      state.current.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInvoices.pending, (state, action) => pending(state[action.meta.arg.type]));
    builder.addCase(getInvoices.fulfilled, (state, action) => {
      state[action.meta.arg.type].loading = false;
      state[action.meta.arg.type].fulfilled = Date.now();
      state[action.meta.arg.type].data.invoices = action.payload.invoices.map((invoice) =>
        invoiceToUI(invoice)
      );
      state[action.meta.arg.type].empty = state[action.meta.arg.type].data.invoices.length <= 0;
    });
    builder.addCase(getInvoices.rejected, (state, action) =>
      error(state[action.meta.arg.type], action)
    );

    builder.addCase(getInvoice.pending, (state) => pending(state.current));
    builder.addCase(getInvoice.fulfilled, (state, action) => {
      state.current.loading = false;
      state.current.fulfilled = Date.now();
      state.current.data = invoiceToUI(action.payload);
      state.current.empty = false;
      const idx = state[action.meta.arg.type].data.invoices.findIndex(
        (item) => item.id === action.payload.id
      );
      state[action.meta.arg.type].data.invoices.splice(idx, 1, invoiceToUI(action.payload));
    });
    builder.addCase(getInvoice.rejected, (state, action) => error(state.current, action));

    builder.addCase(createInvoice.pending, (state, action) => pending(state[action.meta.arg.type]));
    builder.addCase(createInvoice.fulfilled, (state, action) => {
      state[action.meta.arg.type].loading = false;
      state[action.meta.arg.type].fulfilled = Date.now();
      state[action.meta.arg.type].data.invoices.splice(0, 0, invoiceToUI(action.payload));
      state[action.meta.arg.type].empty = state[action.meta.arg.type].data.invoices.length <= 0;
    });
    builder.addCase(createInvoice.rejected, (state, action) =>
      error(state[action.meta.arg.type], action)
    );

    builder.addCase(updateInvoice.pending, (state, action) => pending(state[action.meta.arg.type]));
    builder.addCase(updateInvoice.fulfilled, (state, action) => {
      state[action.meta.arg.type].loading = false;
      state[action.meta.arg.type].fulfilled = Date.now();
      const idx = state[action.meta.arg.type].data.invoices.findIndex(
        (item) => item.id === action.payload.id
      );
      state[action.meta.arg.type].data.invoices.splice(idx, 1, invoiceToUI(action.payload));
      state[action.meta.arg.type].empty = state[action.meta.arg.type].data.invoices.length <= 0;
    });
    builder.addCase(updateInvoice.rejected, (state, action) =>
      error(state[action.meta.arg.type], action)
    );

    builder.addCase(updateEstimateStatus.pending, (state, action) => pending(state["estimate"]));

    builder.addCase(updateEstimateStatus.fulfilled, (state, action) => {
      state["estimate"].loading = false;
      state["estimate"].fulfilled = Date.now();
      const idx = state["estimate"].data.invoices.findIndex(
        (item) => item.id === action.payload.id
      );
      state["estimate"].data.invoices.splice(idx, 1, invoiceToUI(action.payload));
      state["estimate"].empty = state["estimate"].data.invoices.length === 0;
    });

    builder.addCase(updateEstimateStatus.rejected, (state, action) =>
      error(state["estimate"], action)
    );

    builder.addCase(deleteInvoice.pending, (state, action) => pending(state[action.meta.arg.type]));

    builder.addCase(deleteInvoice.fulfilled, (state, action) =>
      remove(state[action.meta.arg.type], action, (st) => st.data.invoices as Writable[])
    );

    builder.addCase(deleteInvoice.rejected, (state, action) =>
      error(state[action.meta.arg.type], action)
    );

    builder.addCase(convertToInvoice.pending, (state) => pending(state.estimate));
    builder.addCase(convertToInvoice.fulfilled, (state, action) => {
      state.estimate.loading = false;
      state.estimate.fulfilled = Date.now();
      const idx = state.estimate.data.invoices.findIndex(
        (item) => item.id === action.meta.arg.estimateId
      );
      state.estimate.data.invoices.splice(idx, 1, {
        ...state.estimate.data.invoices[idx],
        estimate_status: Invoice.estimate_status.INVOICED,
      });
      state.estimate.empty = state.estimate.data.invoices.length <= 0;
    });

    builder.addCase(convertToInvoice.rejected, (state, action) => error(state.estimate, action));

    builder.addCase(addProjectToInvoice.pending, (state, action) =>
      pending(state[action.meta.arg.type])
    );

    builder.addCase(addProjectToInvoice.fulfilled, (state, action) => {
      state[action.meta.arg.type].loading = false;
      state.invoice.fulfilled = Date.now();
      const invoice = state[action.meta.arg.type].data.invoices.find(
        (item) => item.id === action.meta.arg.invoiceId
      );
      invoice.projects
        ? invoice.projects.push(action.payload)
        : (invoice.projects = [action.payload]);
      state[action.meta.arg.type].empty = state[action.meta.arg.type].data.invoices.length <= 0;
    });
    builder.addCase(addProjectToInvoice.rejected, (state, action) =>
      error(state[action.meta.arg.type], action)
    );

    builder.addCase(removeProjectFromInvoice.pending, (state, action) =>
      pending(state[action.meta.arg.type])
    );

    builder.addCase(removeProjectFromInvoice.fulfilled, (state, action) => {
      state[action.meta.arg.type].loading = false;
      state[action.meta.arg.type].fulfilled = Date.now();
      const invoice = state[action.meta.arg.type].data.invoices.find(
        (item) => item.id === action.meta.arg.invoiceId
      );
      const idx = invoice.projects.findIndex((item) => item.id === action.meta.arg.projectId);
      invoice.projects.splice(idx, 1);
      state[action.meta.arg.type].empty = state[action.meta.arg.type].data.invoices.length <= 0;
    });
    builder.addCase(removeProjectFromInvoice.rejected, (state, action) =>
      error(state[action.meta.arg.type], action)
    );
  },
});

export default invoiceSlice.reducer;
export const {
  setCurrentInvoice,
  clearCurrentLocalInvoice,
  clearCurrentInvoiceState,
  setCurrentPartialPayment,
  clearCurrentPartialPayment,
  setHasSaveButton,
  setCurrentInvoiceState,
} = invoiceSlice.actions;
