import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DocumentType, DocumentTypeService } from "../../generated/openapi";
import { logRejectedThunk } from "../../sentry";
import { RootState, StatefulEntity } from "../../store";
import { returnWithErrorWrap } from "../../store/error";
import { error, pending, remove } from "../../store/reducer";

export const getMergedDocumentTypes = createAsyncThunk<
  DocumentType[],
  { businessAccountId: string }
>("document/types/get", async (args, thunk) =>
  returnWithErrorWrap(
    () => DocumentTypeService.getBusinessAccountDocumentTypesMerged(args.businessAccountId),
    thunk
  )
);
export const createDocumentType = createAsyncThunk<
  DocumentType,
  { businessAccountId: string; data: DocumentType }
>("document/type/create", async (args, thunk) =>
  returnWithErrorWrap(
    () => DocumentTypeService.postBusinessAccountDocumentType(args.data, args.businessAccountId),
    thunk
  )
);
export const updateDocumentType = createAsyncThunk<
  DocumentType,
  { businessAccountId: string; data: DocumentType; documentTypeId: string }
>("document/type/update", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      DocumentTypeService.putBusinessAccountDocumentType(
        args.data,
        args.businessAccountId,
        args.documentTypeId
      ),
    thunk
  )
);
export const deleteDocumentType = createAsyncThunk<
  any,
  { businessAccountId: string; documentTypeId: string }
>("document/type/delete", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      DocumentTypeService.deleteBusinessAccountDocumentTypeV2(
        args.businessAccountId,
        args.documentTypeId
      ),
    thunk
  )
);

export const selectDocumentTypesState = (state: RootState): StatefulEntity<DocumentType[]> =>
  state.document.types;

const documentTypesInitialState: StatefulEntity<DocumentType[]> = {
  data: [],
  empty: true,
  loading: false,
};
const documentsSlice = createSlice({
  name: "document",
  initialState: {
    types: documentTypesInitialState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMergedDocumentTypes.pending, (state) => {
      state.types.error = null;
      state.types.loading = true;
    });
    builder.addCase(getMergedDocumentTypes.fulfilled, (state, action) => {
      state.types.data = action.payload;
      state.types.empty = action.payload.length === 0;
      state.types.loading = false;
    });
    builder.addCase(getMergedDocumentTypes.rejected, (state, action) => {
      state.types.error = action.payload;
      state.types.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(createDocumentType.pending, (state) => {
      state.types.error = null;
      state.types.loading = true;
    });
    builder.addCase(createDocumentType.fulfilled, (state, action) => {
      state.types.data.unshift(action.payload);
      state.types.empty = false;
      state.types.loading = false;
    });
    builder.addCase(createDocumentType.rejected, (state, action) => {
      state.types.error = action.payload;
      state.types.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(updateDocumentType.pending, (state) => {
      state.types.error = null;
      state.types.loading = true;
    });
    builder.addCase(updateDocumentType.fulfilled, (state, action) => {
      const index = state.types.data.findIndex((type) => type.id === action.payload.id);
      if (index > -1) {
        state.types.data.splice(index, 1, action.payload);
      } else {
        state.types.data.unshift(action.payload);
      }
      state.types.empty = false;
      state.types.loading = false;
    });
    builder.addCase(updateDocumentType.rejected, (state, action) => {
      state.types.error = action.payload;
      state.types.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(deleteDocumentType.pending, (state) => pending(state.types));
    builder.addCase(deleteDocumentType.fulfilled, (state, action) => remove(state.types, action));
    builder.addCase(deleteDocumentType.rejected, (state, action) => error(state.types, action));
  },
});

export default documentsSlice.reducer;
