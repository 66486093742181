import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, StatefulEntity } from "../../store";
import { error, fulfilled, fulfilledArray, pending } from "../../store/reducer";
import {
  FleetCard,
  FleetPinRequest,
  FleetVehicle,
  FleetCardStatusUpdateRequest,
  FleetAccountBalance,
  FleetDisputeLinkResponse,
  PaymentHistoryResponse,
  FleetInvoicePayRequest,
  FleetPaymentResponse,
  BulkFleetCards,
  BulkFleetCardRequest,
  FleetSharedAuthProfileRanges,
} from "../../generated/openapi";
import { returnWithErrorWrap } from "../../store/error";
import { FleetService, FleetCardResponse } from "../../generated/openapi";
import { fleetCardToUI, fleetCardToAPI } from "./utils";
import { createInitialState } from "../../store/reducer";
import { resetStore } from "../../store/slice";

export interface GetFleetCardsOptions {
  limit?: number;
  page?: number;
  sort?: string;
  direction?: string;
}

export type GetFleetPaymentsOptions = {
  limit?: number;
  page?: number;
  sort?: string;
  direction?: string;
  methods?: string;
  statuses?: string;
  amounts?: string;
  dates?: string;
};

export const createFuelCard = createAsyncThunk<
  FleetCard,
  { accountId: string; data: FleetCard },
  {}
>("fuel/card/create", async (args, thunk) =>
  returnWithErrorWrap(
    () => FleetService.postFleetCard(fleetCardToAPI(args.data), args.accountId),
    thunk
  )
);

export const createFuelCards = createAsyncThunk<
  BulkFleetCards,
  { accountId: string; data: BulkFleetCardRequest },
  {}
>("fuel/cards/create", async (args, thunk) => {
  const { cards, ...otherData } = args.data;
  const data = { ...otherData, cards: cards.map((card) => fleetCardToAPI(card)) };
  return returnWithErrorWrap(() => FleetService.postBulkFleetCards(data, args.accountId), thunk);
});

export const updateFuelCard = createAsyncThunk<
  FleetCard,
  { accountId: string; data: FleetCard },
  {}
>("fuel/card/update", async (args, thunk) =>
  returnWithErrorWrap(
    () => FleetService.putFleetCard(fleetCardToAPI(args.data), args.accountId, args.data.id),
    thunk
  )
);

export const getFuelCardList = createAsyncThunk<
  FleetCardResponse,
  { accountId: string; options?: GetFleetCardsOptions },
  {}
>("fuel/card/list", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      FleetService.getAllFleetCards(
        args.accountId,
        args.options?.limit,
        args.options?.page,
        args.options?.sort,
        args.options?.direction
      ),
    thunk
  )
);

export const getVehicleFromVin = createAsyncThunk<FleetVehicle, { vin: string }, {}>(
  "fuel/card/vehicle/vin",
  async (args, thunk) => returnWithErrorWrap(() => FleetService.getVehicleFromVin(args.vin), thunk)
);

export const getFleetDisputeLink = createAsyncThunk<
  FleetDisputeLinkResponse,
  { accountId: string },
  {}
>("fuel/card/dispute/link", async (args, thunk) =>
  returnWithErrorWrap(() => FleetService.postFleetDisputeLink(args.accountId), thunk)
);

export const getFleetPin = createAsyncThunk<FleetPinRequest, { accountId: string }, {}>(
  "fuel/card/pin",
  async (args, thunk) => returnWithErrorWrap(() => FleetService.getFleetPin(args.accountId), thunk)
);
export const updateFleetPin = createAsyncThunk<
  FleetPinRequest,
  { accountId: string; data: FleetPinRequest }
>("fuel/card/pin/update", async (args, thunk) =>
  returnWithErrorWrap(() => FleetService.putFleetPin(args.data, args.accountId), thunk)
);

export const updateCardStatus = createAsyncThunk<
  FleetCard,
  { accountId: string; cardId: string; data: FleetCardStatusUpdateRequest }
>("fuel/card/status/update", async (args, thunk) =>
  returnWithErrorWrap(
    () => FleetService.putFleetCardStatus(args.data, args.accountId, args.cardId),
    thunk
  )
);

export const activateCard = createAsyncThunk<FleetCard, { accountId: string; cardId: string }, {}>(
  "fuel/card/activate",
  async (args, thunk) =>
    returnWithErrorWrap(
      () => FleetService.putFleetCardStatusActivate(args.accountId, args.cardId),
      thunk
    )
);

export const getFleetAccount = createAsyncThunk<FleetAccount, { accountId: string }, {}>(
  "fuel/card/acccount",
  async (args, thunk) =>
    returnWithErrorWrap(() => FleetService.getFleetAccount(args.accountId), thunk)
);

export const getFleetAccountBalance = createAsyncThunk<
  FleetAccountBalance,
  { accountId: string },
  {}
>("fuel/card/acccount/balance", async (args, thunk) =>
  returnWithErrorWrap(() => FleetService.getFleetAccountBalance(args.accountId), thunk)
);

export const getFleetPaymentHistory = createAsyncThunk<
  PaymentHistoryResponse,
  { accountId: string; options?: GetFleetPaymentsOptions },
  {}
>("fuel/payment/history", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      FleetService.getFleetPaymentHistory(
        args.accountId,
        args.options?.limit,
        args.options?.page,
        args.options?.sort,
        args.options?.direction,
        args.options?.methods,
        args.options?.statuses,
        args.options?.amounts,
        args.options?.dates
      ),
    thunk
  )
);

export const payFleetInvoice = createAsyncThunk<
  FleetPaymentResponse,
  { accountId: string; data: FleetInvoicePayRequest },
  {}
>("fuel/payment/pay", async (args, thunk) =>
  returnWithErrorWrap(() => FleetService.payFleet(args.data, args.accountId), thunk)
);

export const getAuthProfileOptions = createAsyncThunk<FleetSharedAuthProfileRanges, void, {}>(
  "fuel/authprofile/options",
  async (_, thunk) =>
    returnWithErrorWrap(() => FleetService.getFleetSharedAuthProfileRanges(), thunk),
  {
    condition: (_, { getState }) => {
      const {
        fuel: {
          authProfileOptions: { fulfilled, loading },
        },
      } = getState() as RootState;
      return !fulfilled && !loading;
    },
  }
);

const initialCardListState: StatefulEntity<FleetCardResponse> = {
  loading: false,
  empty: true,
  data: { additional_pages: false },
};

const initialCardState: StatefulEntity<FleetCard> = {
  loading: false,
  empty: true,
  data: null,
};

const authProfileOptionsState: StatefulEntity<FleetSharedAuthProfileRanges> = {
  loading: false,
  empty: false,
  data: { spend_per_day: [], transactions_per_day: [] },
};

export const selectFuelCardsState = (state: RootState) => state.fuel.cards;
export const selectFuelCards = (state: RootState) => state.fuel.cards.data.items;
export const selectDisputeLinkState = (state: RootState) => state.fuel.disputeLink;

export const selectFuelCardById = (id: string) =>
  createSelector(selectFuelCards, (cards) => cards?.find((card) => card.id === id));

export const selectFuelAccountBalance = (state: RootState) => state.fuel.accountBalance;
export const selectFuelAccount = (state: RootState) => state.fuel.account;
export const selectFleetPaymentsState = (state: RootState) => state.fuel.payments;
export const selectFuelCardAuthProfileOptions = (state: RootState) => state.fuel.authProfileOptions;
const initialState = {
  cards: initialCardListState,
  card: initialCardState,
  pin: { loading: false, empty: true, data: null },
  accountBalance: { loading: false, empty: true, data: null },
  account: { loading: false, empty: true, data: null },
  disputeLink: { loading: false, empty: true, data: null },
  payments: createInitialState<PaymentHistoryResponse>({ additional_pages: false, payments: [] }),
  authProfileOptions: authProfileOptionsState,
};

const fuelSlice = createSlice({
  name: "fuel",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createFuelCard.pending, (state) => pending(state.cards));
    builder.addCase(createFuelCard.fulfilled, (state, action) =>
      fulfilledArray(
        state.cards,
        { ...action, payload: fleetCardToUI(action.payload) },
        (state) => state.data.items
      )
    );

    builder.addCase(createFuelCard.rejected, (state, action) => error(state.cards, action));

    builder.addCase(createFuelCards.pending, (state) => pending(state.cards));
    builder.addCase(createFuelCards.fulfilled, (state, action) =>
      action.payload.cards.forEach((card) =>
        fulfilledArray(state.cards, { payload: fleetCardToUI(card) }, (state) => state.data.items)
      )
    );
    builder.addCase(createFuelCards.rejected, (state, action) => error(state.cards, action));

    builder.addCase(updateFuelCard.pending, (state) => pending(state.cards));
    builder.addCase(updateFuelCard.fulfilled, (state, action) =>
      fulfilledArray(
        state.cards,
        { ...action, payload: fleetCardToUI(action.payload) },
        (state) => state.data.items
      )
    );

    builder.addCase(updateFuelCard.rejected, (state, action) => error(state.cards, action));
    builder.addCase(getFuelCardList.pending, (state) => pending(state.cards));

    builder.addCase(getFuelCardList.fulfilled, (state, action) => {
      const items = action.payload?.items?.map((item) => fleetCardToUI(item));
      fulfilled(
        state.cards,
        { ...action, payload: { ...action.payload, items: items } },
        (payload: FleetCardResponse) => payload.items?.length <= 0
      );
    });

    builder.addCase(getFuelCardList.rejected, (state, action) => error(state.cards, action));

    builder.addCase(getFleetPin.pending, (state) => pending(state.pin));
    builder.addCase(getFleetPin.fulfilled, (state, action) => fulfilled(state.pin, action));
    builder.addCase(getFleetPin.rejected, (state, action) => error(state.pin, action));

    builder.addCase(getFleetAccountBalance.pending, (state) => pending(state.accountBalance));
    builder.addCase(getFleetAccountBalance.fulfilled, (state, action) =>
      fulfilled(state.accountBalance, action)
    );
    builder.addCase(getFleetAccountBalance.rejected, (state, action) =>
      error(state.accountBalance, action)
    );

    builder.addCase(getFleetAccount.pending, (state) => pending(state.account));
    builder.addCase(getFleetAccount.fulfilled, (state, action) => fulfilled(state.account, action));
    builder.addCase(getFleetAccount.rejected, (state, action) => error(state.account, action));

    builder.addCase(updateFleetPin.pending, (state) => pending(state.pin));
    builder.addCase(updateFleetPin.fulfilled, (state, action) => fulfilled(state.pin, action));
    builder.addCase(updateFleetPin.rejected, (state, action) => error(state.pin, action));

    builder.addCase(updateCardStatus.pending, (state) => pending(state.cards));
    builder.addCase(updateCardStatus.fulfilled, (state, action) =>
      fulfilledArray(
        state.cards,
        { ...action, payload: fleetCardToUI(action.payload) },
        (state) => state.data.items
      )
    );
    builder.addCase(activateCard.pending, (state, action) => pending(state.cards));
    builder.addCase(activateCard.fulfilled, (state, action) =>
      fulfilledArray(
        state.cards,
        { ...action, payload: fleetCardToUI(action.payload) },
        (state) => state.data.items
      )
    );
    builder.addCase(activateCard.rejected, (state, action) => error(state.cards, action));

    builder.addCase(updateCardStatus.rejected, (state, action) => error(state.cards, action));

    builder.addCase(getFleetDisputeLink.pending, (state) => pending(state.disputeLink));
    builder.addCase(getFleetDisputeLink.fulfilled, (state, action) =>
      fulfilled(state.disputeLink, action)
    );
    builder.addCase(getFleetDisputeLink.rejected, (state, action) =>
      error(state.disputeLink, action)
    );

    builder.addCase(getFleetPaymentHistory.pending, (state) => pending(state.payments));
    builder.addCase(getFleetPaymentHistory.fulfilled, (state, action) => {
      fulfilled(
        state.payments,
        {
          ...action,
          payload: action.payload.payments ? action.payload : { ...action.payload, payments: [] },
        },
        (payload: PaymentHistoryResponse) =>
          !action.payload.payments || action.payload.payments.length === 0
      );
    });
    builder.addCase(getFleetPaymentHistory.rejected, (state, action) =>
      error(state.payments, action)
    );

    builder.addCase(payFleetInvoice.pending, (state) => pending(state.payments));
    builder.addCase(payFleetInvoice.fulfilled, (state, action) => {
      fulfilled(state.payments, {
        payload: action.payload.history?.payments
          ? action.payload.history
          : { additional_pages: false, payments: [] },
      });
    });
    builder.addCase(payFleetInvoice.rejected, (state, action) => error(state.payments, action));

    builder.addCase(getAuthProfileOptions.pending, (state) => pending(state.authProfileOptions));
    builder.addCase(getAuthProfileOptions.fulfilled, (state, action) =>
      fulfilled(state.authProfileOptions, action)
    );
    builder.addCase(getAuthProfileOptions.rejected, (state, action) =>
      error(state.authProfileOptions, action)
    );

    builder.addCase(resetStore, () => ({ ...initialState }));
  },
});

export default fuelSlice.reducer;
