import { createTheme, PaletteColorOptions } from "@mui/material/styles";
import {
  alertTheme,
  autocompleteTheme,
  chipTheme,
  linearProgressTheme,
  tabTheme,
  textfieldTheme,
  tooltipTheme,
} from ".";
import colors from "../theme/theme-colors.module.scss";

declare module "@mui/material/styles" {
  interface CustomPalette {
    tertiary?: PaletteColorOptions;
    brand?: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    tertiary: true;
  }
  interface ChipPropsVariantOverrides {
    rounded: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    brand: true;
  }
}
export const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      defaultProps: { notched: false },
      styleOverrides: {
        notchedOutline: {
          top: "0",
          borderRadius: "8px",
          legend: { display: "none" },
        },
      },
    },
    ...textfieldTheme,
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        variant: "filled",
      },
    },
    ...autocompleteTheme,
    MuiPaper: {
      styleOverrides: {
        root: ({ ownerState }) => ({ borderRadius: ownerState.square ? 0 : "8px" }),
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          backdropFilter: "blur(2px)",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: "3px !important",
        },
      },
    },
    ...tabTheme,
    ...chipTheme,
    ...alertTheme,
    ...linearProgressTheme,
    ...tooltipTheme,
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colors["neutral-70"],
          fontSize: "13px",
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors["primary-main"],
      light: colors["primary-light"],
      dark: colors["primary-dark"],
    },
    secondary: {
      main: colors["neutral-white"],
      dark: colors["neutral-light"],
      light: colors["neutral-main"],
      contrastText: colors["primary-main"],
    },
    tertiary: {
      main: colors["tertiary-main"],
      light: colors["tertiary-light"],
      dark: colors["tertiary-dark"],
      contrastText: colors["neutral-white"],
    },
    info: {
      main: colors["neutral-main"],
      light: colors["neutral-light"],
    },
    error: {
      main: colors["critical-main"],
      light: colors["critical-light"],
      dark: colors["critical-dark"],
    },
    success: {
      main: colors["success-main"],
      light: colors["success-light"],
      dark: colors["success-dark"],
    },
    warning: {
      main: colors["warning-main"],
      light: colors["warning-light"],
      dark: colors["warning-dark"],
      contrastText: colors["neutral-white"],
    },
    brand: {
      main: colors["brand-main"],
      light: colors["brand-light"],
      dark: colors["brand-dark"],
      contrastText: colors["neutral-white"],
    },
  },
  typography: {
    fontFamily: `"Inter", "Roboto", sans-serif`,
  },
});

export default theme;
