import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CardService, StripeLinkResponse } from "../../../generated/openapi";
import { RootState } from "../../../store";
import { createInitialState, error, fulfilled, pending } from "../../../store/reducer";
import { returnWithErrorWrap } from "../../../store/error";

export const onboardStripe = createAsyncThunk<StripeLinkResponse, string>(
  "portal/onboard/post",
  async (id, thunk) => returnWithErrorWrap(() => CardService.onboardStripe(id), thunk)
);

export const selectOnboardingState = (state: RootState) => state.portal;

const portalSlice = createSlice({
  name: "portal",
  initialState: createInitialState<StripeLinkResponse>(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(onboardStripe.pending, pending);
    builder.addCase(onboardStripe.fulfilled, fulfilled);
    builder.addCase(onboardStripe.rejected, error);
  },
});

export default portalSlice.reducer;
