import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import commonEN from "./translation/common/en.json";
import componentEN from "./translation/component/en.json";
import layoutEN from "./translation/layout/en.json";
import errorsEN from "./translation/errors/en.json";
import businessEN from "./translation/module/business/en.json";
import fuelEN from "./translation/module/fuel/en.json";
import itemEN from "./translation/module/item/en.json";
import mainEN from "./translation/module/main/en.json";
import networkEN from "./translation/module/network/en.json";
import invoiceEN from "./translation/module/payment/invoice/en.json";
import paymentEN from "./translation/module/payment/en.json";
import publicEN from "./translation/module/public/en.json";
import projectEN from "./translation/module/project/en.json";
import subscriptionEN from "./translation/module/subscription/en.json";
import settingsEN from "./translation/module/settings/en.json";
import userEN from "./translation/module/user/en.json";
import dashboardEN from "./translation/module/dashboard/en.json";

import commonES from "./translation/common/es.json";
import componentES from "./translation/component/es.json";
import layoutES from "./translation/layout/es.json";
import errorsES from "./translation/errors/es.json";
import businessES from "./translation/module/business/es.json";
import fuelES from "./translation/module/fuel/es.json";
import itemES from "./translation/module/item/es.json";
import mainES from "./translation/module/main/es.json";
import networkES from "./translation/module/network/es.json";
import invoiceES from "./translation/module/payment/invoice/es.json";
import paymentES from "./translation/module/payment/es.json";
import publicES from "./translation/module/public/es.json";
import projectES from "./translation/module/project/es.json";
import subscriptionES from "./translation/module/subscription/es.json";
import settingsES from "./translation/module/settings/es.json";
import userES from "./translation/module/user/es.json";
import dashboardES from "./translation/module/dashboard/es.json";

import { isProd } from "../config";

/**
 * Initializes the I18n instance to be used throughout the app.
 */
export const initializeI18n = () => {
  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector) // uses browser language detector
    .init({
      supportedLngs: isProd() ? ["en"] : ["en", "es", "la"], // English is the only support language in prod for now
      defaultNS: "common",
      fallbackLng: isProd() ? "en" : false,
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
      saveMissing: true,
      missingKeyHandler: (lng, ns, key, fallbackValue) => {
        console.warn(
          `i18nKey not found: ${key}, language: ${lng}, namespace: ${ns}, fallbackValue: ${fallbackValue}`
        );
      },
      parseMissingKeyHandler: (key: string) => {
        return `{{i18nKey not found: "${key}"}}`;
      },
      ns: [
        "common",
        "component",
        "dashboard",
        "layout",
        "businessModule",
        "fuelModule",
        "invoiceModule",
        "itemModule",
        "mainModule",
        "networkModule",
        "publicModule",
        "errors",
        "paymentModule",
        "projectModule",
        "subscriptionModule",
        "settingsModule",
        "userModule",
      ],
      resources: {
        en: {
          common: commonEN,
          component: componentEN,
          dashboard: dashboardEN,
          layout: layoutEN,
          businessModule: businessEN,
          fuelModule: fuelEN,
          itemModule: itemEN,
          mainModule: mainEN,
          networkModule: networkEN,
          invoiceModule: invoiceEN,
          paymentModule: paymentEN,
          publicModule: publicEN,
          projectModule: projectEN,
          subscriptionModule: subscriptionEN,
          errors: errorsEN,
          settingsModule: settingsEN,
          userModule: userEN,
        },
        es: {
          common: commonES,
          component: componentES,
          dashboard: dashboardES,
          layout: layoutES,
          businessModule: businessES,
          fuelModule: fuelES,
          itemModule: itemES,
          mainModule: mainES,
          networkModule: networkES,
          invoiceModule: invoiceES,
          paymentModule: paymentES,
          publicModule: publicES,
          projectModule: projectES,
          subscriptionModule: subscriptionES,
          errors: errorsES,
          settingsModule: settingsES,
          userModule: userES,
        },
      },
    });
};
