import { forwardRef } from "react";

export type Animation =
  | "beat"
  | "fade"
  | "beat-fade"
  | "bounce"
  | "flip"
  | "shake"
  | "spin"
  | "spin-pulse"
  | "spin-reverse";
export type Rotation =
  | 90
  | 180
  | 270
  | "flip-horizontal"
  | "flip-vertical"
  | "flip-both"
  | "custom";
export type Size =
  | "2xs"
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10;

export interface FlumeIconProps extends React.HTMLAttributes<HTMLElement> {
  animation?: Animation;
  className?: string;
  encircled?: boolean;
  encircledClassName?: string;
  encircledTheme?: "neutral" | "primary" | "success" | "warning" | "error";
  fixedWidth?: boolean;
  icon: string;
  rotate?: Rotation;
  size?: Size;
  transform?: string;
  variant?: "regular" | "solid" | "light";
}
export const FlumeIcon = forwardRef<HTMLElement, FlumeIconProps>(
  (
    {
      animation,
      className = "",
      encircled = false,
      encircledClassName = "",
      encircledTheme = "neutral",
      fixedWidth = false,
      icon,
      rotate,
      size = "md",
      transform = "",
      variant = "regular",
      ...props
    },
    ref
  ) => {
    const classes = [
      `fa-${icon}`,
      animation ? `fa-${animation}` : "",
      fixedWidth ? "fa-fw" : "",
      getRotationClass(rotate),
      getSizeClass(size),
      `fa-${variant}`,
      className,
    ].reduce((prev, curr) => (curr ? prev + `${prev ? " " : ""}${curr}` : prev), "");
    let element = (
      <i
        className={classes}
        data-fa-transform={transform}
        data-testid={`icon-${icon}`}
        key={icon + classes}
        ref={ref}
        {...props}
      />
    );
    if (encircled) {
      element = (
        <span
          className={`encircled encircled-${size} encircled-theme-${encircledTheme} ${encircledClassName}`}
        >
          {element}
        </span>
      );
    }
    return element;
  }
);

export const getRotationClass = (rotation?: Rotation) => {
  if (!rotation) return "";
  if (rotation === "custom") return "fa-rotate-by";
  return `fa-${Number(rotation) ? "rotate-" : ""}${rotation}`;
};
export const getSizeClass = (size?: Size) => {
  if (!size || size === "md") return "";
  return `fa-${size}${Number(size) ? "x" : ""}`;
};
