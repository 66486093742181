import { FlowIdentifier, Tokens } from "@okta/okta-auth-js";
import OktaSignIn, { WidgetOptions } from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/css/okta-sign-in.min.css";
import { useCallback, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import "./okta.scss";
import { useBodyClass } from "../../component/hook";
import { CenterLayout } from "../../layout/main/CenterLayout";

export interface RegistrationForm {
  firstName?: string;
  lastName?: string;
  email?: string;
}

interface OktaSignInWidgetProps {
  config: WidgetOptions;
  initial?: RegistrationForm;
  onError: (reason: any) => PromiseLike<never> | void;
  onSuccess: (value: Tokens) => Tokens | PromiseLike<Tokens> | void;
  register?: boolean;
}
const OktaSignInWidget = ({
  config,
  initial,
  onSuccess,
  onError,
  register = false,
}: OktaSignInWidgetProps) => {
  useBodyClass("okta");
  const widgetRef = useRef();
  const [queryParams] = useSearchParams();

  // Sets the input values of the sign in field to values passed in query params.
  const handleRegisterRender = useCallback(() => {
    initial?.email && setInputValue("email", initial?.email);
    initial?.firstName && setInputValue("firstName", initial?.firstName);
    initial?.lastName && setInputValue("lastName", initial?.lastName);
  }, [initial]);

  const handleAutoClick = (data) => {
    const view = data.controller;
    if (view === "mfa-verify") {
      (document.getElementsByClassName("sms-request-button")[0] as HTMLElement).click();
    }
  };

  useEffect(() => {
    if (!widgetRef.current) return;

    const localConfig = {
      ...config,
      otp: queryParams.get("otp"),
      state: queryParams.get("state"),
      flow: (register ? "signup" : "login") as FlowIdentifier,
    };

    const widget = new OktaSignIn(localConfig);

    if (register) {
      widget.on("afterRender", handleRegisterRender);
    } else {
      widget.on("afterRender", handleAutoClick);
    }

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    return () => {
      widget.remove();
      widget.off();
    };
  }, [config, handleRegisterRender, queryParams, onSuccess, onError, register]);

  return <CenterLayout className="okta-layout-container" dark ref={widgetRef} />;
};

export default OktaSignInWidget;

/**
 * Sets the input with the given field name to the given value, and then disables it.
 * @param fieldName userProfile.{{fieldName}}, the name field of the input.
 * @param value the value to set the input to.
 */
const setInputValue = (fieldName: string, value: string) => {
  const input = document.querySelector(
    `input[name="userProfile.${fieldName}"]`
  ) as HTMLInputElement;
  input.value = value;
  // Event is needed so that Okta doesn't think the field hasn't changed and throw a validation error.
  input.dispatchEvent(new Event("input", { bubbles: true }));
  input.disabled = true;
};
