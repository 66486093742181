import "./icon.scss";

export enum InitialIconTheme {
  DARK = "container-theme-dark-grey",
  NEUTRAL = "container-theme-neutral",
  PRIMARY = "container-theme-primary",
}

interface InitialIconProps {
  className?: string;
  text: string;
  theme?: InitialIconTheme;
}
export const InitialIcon = ({
  className = "",
  text,
  theme = InitialIconTheme.NEUTRAL,
}: InitialIconProps) => (
  <span className={`initial-icon ${theme} ${className}`} data-testid="initial-icon">
    {text.slice(0, 1).toUpperCase()}
  </span>
);

export default InitialIcon;
