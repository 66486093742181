import { useEffect, useRef } from "react";

const addBodyClass = (className) => document.body.classList.add(className);
const removeBodyClass = (className) => document.body.classList.remove(className);

export const useBodyClass = (className) => {
  useEffect(() => {
    className instanceof Array ? className.map(addBodyClass) : addBodyClass(className);
    return () => {
      className instanceof Array ? className.map(removeBodyClass) : removeBodyClass(className);
    };
  }, [className]);
};

export const useInterval = (callback: Function, delay: number | null): Function => {
  const timerRef: any = useRef();
  const cancel = () => clearInterval(timerRef.current);

  useEffect(() => {
    if (delay !== null) {
      clearInterval(timerRef.current);
      timerRef.current = setInterval(callback, delay);
      return cancel;
    }
  }, [callback, delay]);
  return cancel;
};

export const useTimeout = (callback: Function, timeout: number | null): Function => {
  const timeoutRef = useRef(0);
  const cancel = () => clearTimeout(timeoutRef.current);

  useEffect(() => {
    if (timeout != null) {
      timeoutRef.current = setTimeout(callback, timeout);
      return cancel;
    }
  }, [callback, timeout]);

  return cancel;
};

export const useIsMounted = () => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [mounted]);
  return () => mounted.current;
};
