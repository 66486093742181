import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  CatalogItem,
  CatalogItemCategoryService,
  Category,
  DeleteResponse,
  InvoiceCatalogService,
} from "../../generated/openapi";
import { returnWithErrorWrap } from "../../store/error";
import { logRejectedThunk } from "../../sentry";
import {
  createInitialState,
  error,
  fulfilled,
  fulfilledArray,
  pending,
  remove,
} from "../../store/reducer";

export const ITEM_NAME_DUPE_ERROR = "catalog line item with this name already exists";

export interface GetItemsOptions {
  search?: string;
  limit?: number;
  skip?: number;
}

export const getItems = createAsyncThunk<
  CatalogItem[],
  { businessId: string; options?: GetItemsOptions }
>("items/get", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      InvoiceCatalogService.getCatalogItems(
        args.businessId,
        args.options?.search,
        args.options?.limit,
        args.options?.skip
      ),
    thunk
  )
);
// @TODO update to convert amount values to minor currency for API, then to decimal for front end usage.
// Will need to update the ItemForm and LineItemController once done so.
export const createItem = createAsyncThunk<CatalogItem, { businessId: string; item: CatalogItem }>(
  "items/create",
  async (args, thunk) =>
    returnWithErrorWrap(
      () => InvoiceCatalogService.addCatalogItem(args.item, args.businessId),
      thunk
    )
);
export const updateItem = createAsyncThunk<CatalogItem, { businessId: string; item: CatalogItem }>(
  "items/update",
  async (args, thunk) =>
    returnWithErrorWrap(
      () =>
        InvoiceCatalogService.updateCatalogItem(args.item, args.businessId, args.item.catalog_id),
      thunk
    )
);
export const deleteItem = createAsyncThunk<DeleteResponse, { businessId: string; itemId: string }>(
  "items/delete",
  async (args, thunk) =>
    returnWithErrorWrap(
      () => InvoiceCatalogService.deleteCatalogItemV2(args.businessId, args.itemId),
      thunk
    )
);

export type GetItemCategoryArgs = {
  businessAccountId: string;
  search?: string;
  limit?: number;
  skip?: number;
};
/**
 * Gets the item catalog categories belonging to a business account that match the search value, all if none provided.
 */
export const getItemCategories = createAsyncThunk<Category[], GetItemCategoryArgs>(
  "item/categories/get",
  async (args, thunk) =>
    returnWithErrorWrap(
      () =>
        CatalogItemCategoryService.getCategories(
          args.businessAccountId,
          args.search,
          args.limit,
          args.skip
        ),
      thunk
    )
);
/**
 * Gets an item catalog category.
 */
export const getItemCategory = createAsyncThunk<
  Category,
  { businessAccountId: string; categoryId: string }
>("item/category/get", async (args, thunk) =>
  returnWithErrorWrap(
    () => CatalogItemCategoryService.getCategory(args.businessAccountId, args.categoryId),
    thunk
  )
);
/**
 * Creates an item catalog category.
 */
export const createItemCategory = createAsyncThunk<
  Category,
  { businessAccountId: string; data: Category }
>("item/category/create", async (args, thunk) =>
  returnWithErrorWrap(
    () => CatalogItemCategoryService.addCategory(args.data, args.businessAccountId),
    thunk
  )
);
/**
 * Updates an item catalog category.
 */
export const updateItemCategory = createAsyncThunk<
  Category,
  { businessAccountId: string; data: Category }
>("item/category/update", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      CatalogItemCategoryService.updateCategory(args.data, args.businessAccountId, args.data.id),
    thunk
  )
);
/**
 * Deletes an item catalog category.
 */
export const deleteItemCategory = createAsyncThunk<
  Category,
  { businessAccountId: string; categoryId: string }
>("item/category/delete", async (args, thunk) =>
  returnWithErrorWrap(
    () => CatalogItemCategoryService.deleteCategory(args.businessAccountId, args.categoryId),
    thunk
  )
);

export const selectItemsState = (state: RootState) => state.item.items;
export const selectItemCategoriesState = (state: RootState) => state.item.categories;

const itemSlice = createSlice({
  name: "lineitem",
  initialState: {
    categories: createInitialState<Category[]>([]),
    items: createInitialState<CatalogItem[]>([]),
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getItems.pending, (state) => {
      state.items.error = null;
      state.items.loading = true;
    });
    builder.addCase(getItems.fulfilled, (state, action) => {
      state.items.data = action.payload;
      state.items.empty = !action.payload || action.payload.length === 0;
      state.items.loading = false;
      state.items.fulfilled = Date.now();
    });
    builder.addCase(getItems.rejected, (state, action) => {
      state.items.error = action.payload;
      state.items.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(createItem.pending, (state) => {
      state.items.error = null;
      state.items.loading = true;
    });
    builder.addCase(createItem.fulfilled, (state, action) => {
      state.items.data.push(action.payload);
      state.items.empty = state.items.data.length === 0;
      state.items.loading = false;
      state.items.fulfilled = Date.now();
    });
    builder.addCase(createItem.rejected, (state, action) => {
      state.items.error = action.payload;
      state.items.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(updateItem.pending, (state) => {
      state.items.error = null;
      state.items.loading = true;
    });
    builder.addCase(updateItem.fulfilled, (state, action) => {
      const index = state.items.data.findIndex(
        (item) => item.catalog_id === action.payload.catalog_id
      );
      if (index > -1) {
        state.items.data[index] = action.payload;
      }
      state.items.empty = state.items.data.length === 0;
      state.items.loading = false;
      state.items.fulfilled = Date.now();
    });
    builder.addCase(updateItem.rejected, (state, action) => {
      state.items.error = action.payload;
      state.items.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(deleteItem.pending, (state) => pending(state.items));

    builder.addCase(deleteItem.rejected, (state, action) => error(state.items, action));

    builder.addCase(deleteItem.fulfilled, (state, action) => {
      state.items.data = state.items.data.filter((item) => item.catalog_id !== action.payload.id);
      state.items.empty = state.items.data.length === 0;
      state.items.loading = false;
      state.items.fulfilled = Date.now();
    });

    builder.addCase(getItemCategories.pending, (state) => pending(state.categories));
    builder.addCase(getItemCategories.fulfilled, (state, action) =>
      fulfilled(state.categories, action)
    );
    builder.addCase(getItemCategories.rejected, (state, action) => error(state.categories, action));

    builder.addCase(getItemCategory.pending, (state) => pending(state.categories));
    builder.addCase(getItemCategory.fulfilled, (state, action) =>
      fulfilledArray(state.categories, action)
    );
    builder.addCase(getItemCategory.rejected, (state, action) => error(state.categories, action));

    builder.addCase(createItemCategory.pending, (state) => pending(state.categories));
    builder.addCase(createItemCategory.fulfilled, (state, action) =>
      fulfilledArray(state.categories, action)
    );
    builder.addCase(createItemCategory.rejected, (state, action) =>
      error(state.categories, action)
    );

    builder.addCase(updateItemCategory.pending, (state) => pending(state.categories));
    builder.addCase(updateItemCategory.fulfilled, (state, action) =>
      fulfilledArray(state.categories, action)
    );
    builder.addCase(updateItemCategory.rejected, (state, action) =>
      error(state.categories, action)
    );

    builder.addCase(deleteItemCategory.pending, (state) => pending(state.categories));
    builder.addCase(deleteItemCategory.fulfilled, (state, action) =>
      remove(state.categories, action)
    );
    builder.addCase(deleteItemCategory.rejected, (state, action) =>
      error(state.categories, action)
    );
  },
});

export default itemSlice.reducer;
