import { Components } from "@mui/material/styles";

const baseStyles = {
  ".MuiAutocomplete-inputRoot.MuiOutlinedInput-root": {
    paddingTop: "9px",
    paddingBottom: "9px",
    ".MuiInputBase-input": {
      paddingTop: "6px",
      paddingBottom: "6px",
    },
    ".MuiChip-root": {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
  "label + .MuiAutocomplete-inputRoot.MuiOutlinedInput-root": {
    paddingTop: "20px",
    paddingBottom: "3px",
    ".MuiInputBase-input": {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    ".MuiInputAdornment-root.MuiInputAdornment-positionStart, .MuiInputAdornment-root.MuiInputAdornment-positionEnd":
      {
        marginTop: "0",
      },
    ".MuiInputAdornment-root.MuiInputAdornment-positionStart": {
      paddingLeft: "5px",
    },
  },
  // Sets a min width for the input on autocompletes where you can select multiple values so that
  // the user is able to see what they're typing better.
  ".MuiButtonBase-root.MuiChip-root + .MuiInputBase-input.MuiInputBase-inputAdornedStart": {
    minWidth: "100px",
  },
};

const smallStyles = {
  ".MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "12px",
    ".MuiInputBase-input": {
      paddingTop: "4px",
      paddingBottom: "4px",
    },
    ".MuiChip-root": {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
  "label + .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
    paddingTop: "16px",
    paddingBottom: "2px",
    ".MuiInputBase-input": {
      paddingTop: "1px",
      paddingBottom: "1px",
    },
    ".MuiInputAdornment-root.MuiInputAdornment-positionStart, .MuiInputAdornment-root.MuiInputAdornment-positionEnd":
      {
        marginTop: "0",
      },
  },
};

export const autocompleteTheme: Components = {
  MuiAutocomplete: {
    styleOverrides: {
      root: { ...baseStyles, ...smallStyles },
    },
  },
};
