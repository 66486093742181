/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address } from './Address';
import type { Capabilities } from './Capabilities';
import type { EstimatedSettlementDates } from './EstimatedSettlementDates';
import type { ExternalAccount } from './ExternalAccount';
import type { Fleet } from './Fleet';
import type { Onboarding } from './Onboarding';
import type { Payment } from './Payment';
import type { Prefund } from './Prefund';
import type { Subscription } from './Subscription';

export type BusinessAccount = {
    account_category?: BusinessAccount.account_category;
    address: Address;
    alias?: string;
    business_type?: string;
    capabilities?: Capabilities;
    contact_name?: string;
    created_at?: string;
    email: string;
    error?: string;
    estimated_settlement_dates?: EstimatedSettlementDates;
    external_account?: ExternalAccount;
    fleet?: Fleet;
    flume_tag?: string;
    id?: string;
    individual_category?: BusinessAccount.individual_category;
    last_updated_at?: string;
    legal_structure: BusinessAccount.legal_structure;
    naics_code: string;
    name?: string;
    onboarding?: Onboarding;
    operation_description?: string;
    owner_user_account?: string;
    phone: string;
    prefund?: Prefund;
    recent_payments?: Array<Payment>;
    status?: BusinessAccount.status;
    subscription?: Subscription;
    tax_id: string;
    tax_type?: BusinessAccount.tax_type;
}

export namespace BusinessAccount {

    export enum account_category {
        INDIVIDUAL = 'individual',
        BUSINESS = 'business',
    }

    export enum individual_category {
        CUSTOMER = 'customer',
        EMPLOYEE = 'employee',
    }

    export enum legal_structure {
        CORPORATION = 'corporation',
        LIMITED_LIABILITY_COMPANY = 'limited_liability_company',
        LIMITED_LIABILITY_PARTNERSHIP = 'limited_liability_partnership',
        NON_PROFIT_DOMESTIC_CORPORATION = 'non_profit_domestic_corporation',
        S_CORPORATION = 's_corporation',
        SOLE_PROPRIETOR = 'sole_proprietor',
    }

    export enum status {
        GATHER_INFORMATION = 'gather_information',
        ONBOARDING = 'onboarding',
        COMPLETE = 'complete',
        SUSPENDED = 'suspended',
        RESTRICTED = 'restricted',
        STATE_WAITLIST = 'state_waitlist',
        DOCUMENT_VERIFICATION = 'document_verification',
    }

    export enum tax_type {
        SSN = 'SSN',
        EIN = 'EIN',
        TIN = 'TIN',
    }


}
