import { useCallback, useEffect, useRef } from "react";
import { CancelablePromise } from "../generated/openapi";
import { CurrencyFormatOptions } from "./format";
import { get } from "lodash";
import { FieldErrors, FieldValues, RegisterOptions, UseFormRegister } from "react-hook-form";
export const usePrevious = <T = any>(value: T): T => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
/**
 *
 * @param condition A function to determine when it's time to execute the func.
 * @param func A function that does the effect once.  Typically dispatching an action to fetch data.
 * @param deps Deprecating deps it breaks the static evaluation for react/eslint.  It's better to use the existing parameters
 * that are wrapped in their own useCallbacks.
 */
export const useEffectOnce = (condition: () => boolean, func: () => any, deps?: Array<any>) => {
  const hasCalled = useRef(false);
  useEffect(() => {
    if (!hasCalled.current && condition()) {
      func();
      hasCalled.current = true;
    }
  }, [hasCalled, condition, func]);
};

export const useFormatCurrency = (opt: CurrencyFormatOptions = { showCents: true }) => {
  const ref = useRef(
    new Intl.NumberFormat(opt?.locale || "en-US", {
      style: "currency",
      currency: opt?.currency || "USD",
      minimumFractionDigits: opt.showCents === true ? 2 : 0,
    })
  );

  return useCallback(
    (amount: number) => {
      let num = amount || 0;
      num = opt.normalized ? num / 100 : num;
      return ref.current.format(num);
    },
    [ref, opt.normalized]
  );
};
/**
 * This hook helps clean up the cancellable promises that the Service layer creates.
 * Using this reduces the boilerplate of keeping a reference to the last service call.
 * @param A function to fetch API data
 * @returns A callable function that returns a Promise to return data
 */
export function useServiceCallback<T, Y>(func: (val: Y) => CancelablePromise<T>) {
  const request = useRef<CancelablePromise<T>>(null);
  useEffect(() => {
    return () => request.current?.cancel();
  }, [func, request]);

  const service = useCallback(
    (val: Y) => {
      request.current?.cancel();
      request.current = func(val);
      return request.current;
    },
    [func, request]
  );
  return service;
}

export function useRegister<T = FieldValues>(
  register: UseFormRegister<T>,
  errors: FieldErrors<T>,
  prefix?: string
) {
  return useCallback(
    (name: any, opts?: RegisterOptions<T, any>) => {
      name = prefix ? `${prefix}.${name}` : name;
      const { ref, ...rest } = register(name, opts);
      return {
        inputRef: ref,
        error: Boolean(get(errors, name)),
        helperText: get(errors, name)?.message,
        ...rest,
      };
    },
    [register, errors, prefix]
  );
}
